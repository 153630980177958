/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
@require 'style/settings/colors'

.plan-modal
    padding-top 0
    max-height: 600px !important
    .ks-is-level-low .kp-plan-icon__bgd
      fill $event_low_level_color
    .ks-is-level-middle .kp-plan-icon__bgd
      fill $event_middle_level_color
    .ks-is-level-high .kp-plan-icon__bgd
      fill $event_high_level_color
    .ks-is-level-critical .kp-plan-icon__bgd
      fill $event_critical_level_color

.plan-modal__map
    margin-top: 20px
