/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.list-item
  .list-item-btn-actions
    display none
    float right
    color #333

.list-item:&hover
  .list-item-btn-actions
    display block

.list-item.selected
  background-color #eee


.list-item-btn-actions
  font-size 15px
  padding 5px

.list-add-text
  padding-left 36px
  color #999
  font-size 14px

.list-add-text:&hover
  color #333
  cursor pointer
