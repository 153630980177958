/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-alarms-incident-filter__header
    display: flex

.ks-alarms-incident-filter__header-title
    font-weight: bold
    cursor: pointer

.ks-alarms-incident-filter__header-clear
    margin-left: auto

.ks-alarms-incident-filter__status
    .value-selector-all-title
        width: 100%
        max-width: 100%
        text-align: left
    .caret
        margin-left: -10px
        margin-right: 0

.ks-alarms-incident-filter__summary
    font-weight: bold
    margin-top: 25px
    margin-bottom: 10px
