/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for text inputs
**/
@require 'kp/player/common/conf'

.kp-input
    padding: 0 4px
    height: $btnHeight
    background: $clDialogInput
    border: 1px solid lighten($clDialogInput, 5%)

.kp-input.is-disabled
    cursor: not-allowed