/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.org-logo-container
    height: 140px

.org-logo-container img
    padding: 0 10px

.organizations-add-text
    padding-top 10px

.users-logo-miniature
    height 24px
    width 24px
    margin-right: 5px

.users-container
    padding 0 10px 10px 10px
    font-size 16px
    margin-top: 67px
    overflow-x: hidden
    overflow-y: auto

.user-avatar-container
    height: 200px

.user-avatar-container img
    padding: 0 10px

.thumbnail .user-avatar-wrapper
    height 180px

.users-static-panel //content makes this panel 100% width of parent
    position: fixed
    padding: 15px 15px 3px
    background: #f8f8f8
    border-bottom: 1px solid #ccc
    z-index: $zIndex.fixedPanelLvl2
