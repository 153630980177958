/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.dtselector-timepicker
  display inline-block
  input
      max-width: 50px
      margin: 0 10px

.dtselector-btn-group
  margin-right 20px
  float right

.form-control.dtselector-datetime
  background-color #fff
  text-align center
  cursor pointer

.dtselector-datetime-value
    &:hover, &:focus
        text-decoration: none
    &.disabled input
        cursor: default

.dtselector-btn-success
  margin-right 20px
  margin-bottom 5px
  width 90px
  float right

.dtselector-btn-now
  margin-left 20px
  margin-bottom 5px
  width 90px

.datetimepicker.dtselector
  width auto
  min-width 250px