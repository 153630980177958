/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.incident-select-modal .incident-select-modal__body
    height: 80vh
    flex: 1
    flex-direction: column
    overflow: hidden

.incident-select-modal__incident-user
    display: inline-block

.incident-select-modal__incident-user-icon
    width: 35px
    height: 35px
    display: inline-block

.incident-select-modal__incident-date
    display: inline-block

.incident-select-modal__incidents
    overflow: auto
    margin-right: -35px
    padding-right: 35px
    margin-top: 20px
    height: calc(100% - 80px)

.incident-select-modal__incident
    box-shadow: rgba(0,0,0,.3) 1px 1px 3px
    background: white
    margin-bottom: 20px
    border: 1px solid rgba(0,0,0,.2)

.incident-select-modal__incident-header
    margin-bottom: 10px

.incident-select-modal__incident-events
    flex: 1
    justify-content: flex-start

.incident-select-modal__incident-event
    width: 200px
    height: 150px
    margin-right: 7px
    border: none !important
    box-shadow: rgba(0,0,0,.3) 0 1px 3px
