/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.scheduler-form-modal__weekday
    display: inline-block
    margin-right: 5px

.scheduler-form-modal__weekday-label
    font-weight: 400
    margin-right: 5px
