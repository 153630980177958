/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * plugin_common.styl
 *
 * Description : common style for plugins
 *
 * Created by Maxim Rodionov on 25/05/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/plan/common/conf'

.kp-plan-plugin.is-not-clickable
    .kp-plan-plugin-body
        cursor default

.kp-plan-plugin-link.is-not-binded
    display none

.kp-plan-plugin.is-draggable
    .kp-plan-plugin-body
        cursor move
    .kp-plan-plugin-link
        opacity 1

//LABEL
.kp-plan-label
    &__text // NOTE: font-size passed in js code to WORKAROUND some crosbrowser issues
        fill #fff
        text-anchor start
    &__bgd
        fill: #555
        stroke: #555
        stroke-width: 2 // instead of padding

.kp-plan-label.is-hidden
    display: none

// ICON
.kp-plan-icon
    cursor: pointer
    &__bgd
        fill: white
        stroke: rgba(238, 238, 238, 0.51)
        stroke-width: 2px
    &__body
        fill $clMuted
    &.mod-not-clickable
        cursor: default

.kp-plan-icon__body
    .kp-plan-icon.is-connected &
        fill $clSuccess
    .kp-plan-icon.is-error &
        fill $clError
    .kp-plan-icon.is-connected.mod-bypass &, .kp-plan-icon.is-error.mod-bypass &
        fill $clWarning
    .kp-plan-icon.is-no-signal &
        fill $clMuted
    .kp-plan-icon.is-draggable &
        fill: #fff

.kp-plan-icon__bgd
    .kp-plan-icon.is-draggable &
        fill: #428bca

.kp-plan-icon.is-draggable
    cursor: move

// CHANNEL LINK
.kp-plan-ch-link
    stroke: #428bca
    stroke-width 2
    opacity 0.3

.kp-plan-ch-link.is-draggable
    opacity: 1

.kp-plan-ch-link.is-not-binded
    display: none

.kp-plan-ch-link-marker
    fill: #428bca

// ROTATE CONTROL
.kp-plan-rotate-ctrl
    cursor: pointer
    stroke: $clMuted
    stroke-width: 2
    fill: #fff
