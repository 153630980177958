/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'style/settings/colors'

.ks-incident-row
    padding: 20px
    max-width: 1200px

.ks-incident-row__user
    display: inline-block

    .ks-incident-row__user-icon
        width: 24px
        height: 24px

.ks-incident-row__date
    display: inline-block

.ks-incident-row__header
    display: flex
    font-size: 15px
    border-bottom: 1px solid #d7d7d7
    padding-bottom: 15px

.ks-incident-row__header-right
    margin-left: auto
    display: flex

.ks-incident-row__header-field.mod-margin-left
    margin-left: 40px

.ks-incident-row__header-field.mod-margin-right
    margin-right: 40px

.ks-incident-row__header-field-value
    font-weight: bold

    &.mod-muted
        font-weight: normal
        color: #878787

.ks-incident-row__header-icon
    display: inline-block
    width: 20px
    height: 20px
    vertical-align: middle

.ks-incident-row__ctrls
    width: 25px
    color: #adadad

.ks-incident-row__section
    margin-top: 20px

.ks-incident-row__section-header
    font-size: 15px
    margin-bottom: 5px
    display: flex

.ks-incident-row__section-events
    border-bottom: 1px solid #d7d7d7
    padding-bottom: 15px

    &.mod-last
        border-bottom: none
        padding-bottom: 0

.ks-incident-row__events-header
    cursor: pointer
    align-self: center

.ks-incident-row__events
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    flex-direction: row

.ks-incident-row__event
    width: 200px
    margin: 0 15px 10px 0
    border: none !important
    border-radius: 2px
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19)
    .ks-incident-event__image
        height: 140px

.ks-incident-row__section-comments
    max-width: 700px

.ks-incident-row__comments-header
    cursor: pointer
    align-self: center

.ks-incident-row__comments-add
    background-color: rgba(0, 0, 0, 0.06)
    border-radius: 8px
    padding: 1px 8px
    cursor: pointer
    color:  #878787
    font-weight: 400

    &.mod-right
        margin-left: auto

.ks-incident-row__new-comment
    margin-bottom: 10px

.ks-incident-row__new-comment-text
    resize: none
    width: 100%

.ks-incident-row__comment
    padding: 15px
    margin-bottom: 5px
    background-color: #f9f9f9
    border: 1px solid #d7d7d7
    border-radius: 4px

.ks-incident-row__comment-header
    margin-bottom: 10px
    display: flex

.ks-incident-row__comment-user-icon
    width: 24px
    height: 24px
    display: inline-block

.ks-incident-row__comment-user-text
    font-weight: bold
    margin-left: 5px

.ks-incident-row__comment-text
    padding-left: 30px
    color: #8f9080
    word-wrap: break-word

.ks-incident-row__comment-date
    margin-left: 10px

.ks-incident-row__comment-ctrls
    margin-left: auto
    display: none
    .ks-incident-row__comment:hover &
        display: block
.ks-incident-row__comment-ctrls-icon
    &:hover
        cursor: pointer
        color: #000

.ks-incident-row.mod-selectable:hover
    cursor: pointer
    background-color: $selectionColor--dark
