/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Common style for all on video dialogs
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'


// Should be passed in player box
.kp-player-dialog
    position absolute
    width: 100%
    left: 0
    bottom: 0
    display: flex
    justify-content: flex-end
    pointer-events: none; // transparent part should bypass mouse events
    &__cont
        pointer-events: auto;
        position: relative
        display: flex
        flex-wrap: wrap
        justify-content: center
        align-items: center
        background $clDialogBgd
        padding: 8px 4px
        &__item
            margin: 4px 4px  // for inline blocks

.kp-player-dialog.mod-center
    justify-content: center
.kp-player-dialog.mod-left
    justify-content: flex-start

.kp-player-dialog__cont.mod-width-100
    width: 100%
