/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.ks-layout-cell
  float left
  position relative
  background-color #dcdcdc
  border: 4px solid $mainBgColor

  .cell-label
    position absolute
    text-align center
    color #666
    top 50%
    left 50%
    transform translate(-50%, -50%)
    font-size 24px
    font-weight bold

  .cell-content
    width 100%
    cursor pointer
    display none // visible by .mod-non-empty
    height: 100%;
    overflow hidden

  &.mod-non-empty
    .cell-content
      display block
      background #333

  .cell-content-empty-img
    color rgba(149, 149, 149, 0.8)

  .annotation
    position absolute
    top 0
    left 0
