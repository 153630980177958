/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'application/**/*.styl'
@require 'auth/style/main.styl'
@require 'cameras/style/main.styl'
@require 'diagnostic/style/main.styl'
@require 'downloadjob/style/main.styl'
@require 'errors/style/main.styl'
@require 'events/style/main.styl'
@require 'alarms/**/*.styl'
@require 'faces/**/*.styl'
@require 'crm/**/*.styl'
@require 'core/**/*.styl'
@require 'admin/**/*.styl'

@require 'kp/plan/plan.styl'
@require 'kp/player/plugins/sidebar/sidebar'
@require 'kp/player/plugins/pf_search/pf_search_plugin'
@require 'kp/player/player'
@require 'kp/svg/main'
@require 'kp/pika/pika'
@require 'kp/utils/main.styl'

@require 'layout/**/*.styl'
@require 'layouts/style/main.styl'
@require 'licenses/style/main.styl'
@require 'lsupgrades/style/main.styl'
@require 'map/style/*.styl'
@require 'mobile/style/main.styl'
@require 'organizations/style/*.styl'
@require 'plans/style/main.styl'
@require 'schedulers/**/*.styl'
@require 'seclog/seclog.styl'
@require 'settings/**/*.styl'
@require 'style/settings/*.styl'
@require 'style/elements/*.styl'
@require 'style/utils/*.styl'
@require 'style/*.styl'
@require 'widgets/**/*.styl'
@require 'plates/**/*.styl'


@require 'statistics/style/main.styl'
@require 'style/creation_card.styl'
@require 'users/style/main.styl'

//patch for tooltip
.tooltip
  word-wrap break-word
