/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.admin-lsfirmwares-view
  $padding = 15px
  padding-left $padding

  .toolbar-wrapper
    margin-top 15px
  .row
    padding-left $padding

  .uploading-progress
    width 500px
    margin-top 10px

  .no-firmware
    margin-top 20px

  .firmware
    margin-top 20px
