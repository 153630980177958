/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * channel.styl
 *
 * Description : style for channel widget
 *
 * Created by Maxim Rodionov on 24/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/plan/common/conf'

.kp-plan-ch
    &-lens
        fill black
        cursor pointer

.kp-plan-ch-snapshot
    &-noimg-text
        font-size 14px
        fill darken($clPlanBgdText, 10%)
        text-anchor middle
    &-noimg-bgd
        fill darken($clPlanBgd, 10%)
        stroke-width 0
    &-err-text
        font-size 14px
        fill $clErrText
        text-anchor middle
    &-err-bgd
        fill $clError
        opacity 0.7

.kp-plan-ch.is-draggable
    .kp-plan-ch-lens
        cursor pointer
    .kp-plan-ch-body
        cursor move
