/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.leaflet-map
    height: 300px

.leaflet-map-full-height
    .leaflet-map
        height: calc(100vh - 55px)

.leaflet-tile
    filter: brightness(1.05) saturate(0.8) contrast(1.05) grayscale(0.6)

.map-custom-marker
    border: 0
    background: transparent

.leaflet-popup_img
    height: 150px
    min-width: 200px
    position: relative

.leaflet-label-error
    display: block
    position: absolute
    bottom: 0
    left: 0
    right: 0
    background: rgba(201,48,44,0.5)
    color: #fff
    text-align: center
    font-size: 12px
    padding: 3px 0
    span
        display: block

.leaflet-map-icon
    width: 25px
    height: 25px
    padding: 5px
    border-radius: 50%
    svg
        fill: #fff
    &.mod-cam-icon
        transform: rotate(90deg)
    &.mod-state-default
        background-color: #777
    &.mod-state-online
        background-color: #34c234
    &.mod-state-error
        background-color: #d9534f
    &.mod-state-offline
        background-color: #777
    &.mod-state-bypass
        background-color: #d58512
    &.mod-armed
        position: relative
        &:after
            display: block
            content: ''
            position: absolute
            left: -5px
            right: -5px
            top: -5px
            bottom: -5px
            border-radius: 50%
            border: 3px solid #d9534f
    &.mod-armed.mod-state-offline
        &:after
            border: none
            left: 0
            right: 0
            top: 0
            bottom: 0
            box-shadow: 0 0 0 rgba(217,83,79, 1);
            animation: pulse 1.5s infinite;

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(217,83,79, 1);
    }
    85% {
        -webkit-box-shadow: 0 0 0 15px rgba(217,83,79, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(217,83,79, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(217,83,79, 1);
        box-shadow: 0 0 0 0 rgba(217,83,79, 1);
    }
    90% {
        -moz-box-shadow: 0 0 0 15px rgba(217,83,79, 0);
        box-shadow: 0 0 0 15px rgba(217,83,79, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(217,83,79, 0);
        box-shadow: 0 0 0 0 rgba(217,83,79, 0);
    }
}

.leaflet-checkbox-valign
    vertical-align: top

.leaflet-map-text
    position: absolute
    left: 30px
    top: 10px
    background-color: #fff
    border-radius: 5px
    color: #000
    font-size: 12px
    padding: 1px 4px
    -webkit-box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.75);
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.75);
    max-width: 150px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.leaflet-container .leaflet-control-search
    margin-left: 13px