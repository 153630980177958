/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Adara Belaya <adara.belaya@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.modal-permissions__dots-menu-cont
    width: 60px

.modal-permissions__dots-menu
    visibility: hidden

.modal-permissions__dots-menu.is-visible,
.modal-permissions__item:hover  .modal-permissions__dots-menu
    visibility: visible
