/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
 @require 'style/settings/sizes'

.no-downloads
    height: s('calc(100vh - %s)',$navHeight)

.download-spinner
  position absolute
  top 8px
  left 5%
  width 90%
  background-color rgba(200, 200, 200, 0.6)

.download-camera-name
  top 5px
  right 5px

.download-time
  top 5px
  right auto
  left 5px
  float left

.download-status
  text-align center

.downloadjobs
  background: $mainBgColor
  min-height 600px;
  padding: 15px 25px;
  height: calc(100vh - 55px)

.downloadjobs .thumb-element .thumbnail
  padding: 8px 8px 5px!important;

.downloadjobs .thumb-element .thumbnail h3
  font-size: 16px
  margin-top: 10px;

.downloadjobs .thumb-element .thumbnail .caption
  font-size: 13px;
  line-height: 18px;
  text-transform: lowercase;
