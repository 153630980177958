/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * btn.styl
 *
 * Description : common style for player controls buttons
 *
 * Created by Maxim Rodionov on 09/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

.kp-btn
    display inline-block
    padding 5px
    margin-bottom 0
    font-size 12px
    line-height 14px
    text-align center
    white-space nowrap
    vertical-align middle
    cursor pointer
    m-user-select(none)
    border 0 solid transparent  // override user agent button:border
    border-radius 2px
    color $clText
    background $clBtnBgd
    transition: background $ctrlsTransition

.kp-btn.mod-small
    padding 3px 5px

.kp-btn.mod-primary
    background #3071a9  // bootstrap primary key :hover color to be in consistence with inverse style
    &:hover
        background $clBtnActiveBgd

.kp-btn.mod-with-icon
    padding 5px
    width 24px
    height 24px

.kp-btn.mod-with-small-icon // used by create event, play-pause, prev-next buttons
    padding 6px
    width 24px
    height 24px

.kp-btn.mod-with-play
    padding-left: 8px

.kp-btn.mod-round
    border-radius: 50%

.kp-btn:hover
    background $clBtnHoverBgd

.kp-btn:focus
    outline none  // override user agent button:focus

.kp-btn.is-active
.kp-btn:active
    background-color $clBtnActiveBgd

.kp-btn.is-disabled
.kp-btn.is-disabled:hover
    opacity .65
    cursor not-allowed
    background $clBtnBgd
