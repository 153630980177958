/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require '../localserver_form_modal/localserver_form_modal.styl'

ul.nav > li > .diagnostic-btn-group
  margin-left 5px
  padding: 0

.navbar-nav.navbar-right.diagnostic-nav-right:last-child
  margin-right 15px

.diagnostic-column-filter-container
  margin-top 7px

.diagnostic-column-filter
  text-decoration none

.diagnostic-column-filter-list > li
  padding-left 10px

.diagnostic-table.table
  td
    vertical-align middle
    padding-top 10px
    padding-bottom 10px
  p
    margin-bottom 0

.diagnostic-select-column
  $w = 35px
  max-width $w
  width $w
  min-width $w
  padding-left 15px !important
  .ks-checkbox
    vertical-align text-bottom

.diagnostic-actions-column
  $w = 25px
  max-width $w
  width $w
  min-width $w
  .dropdown
    .dropdown-toggle
      display inline-block
      width 15px
      height 15px
      color #bfbfbf
      vertical-align text-bottom
      &:hover
        color black
    &.open .dropdown-toggle
      color black

.diagnostic-name-column
  max-width 50px

.diagnostic-storage-state
  float right

.diagnostic-icon
  display inline-block
  width 36px
  height 36px
  text-align center

.invalid-state
  color #d91e1e

.row-centered
  text-align center

.modal-open .modal.offline-mode-modal div.modal-dialog
  width 1400px

.modal-open .modal.event-full-modal div.modal-dialog
  display flex
  width auto
  margin 30px auto
  align-items center
  justify-content center

.modal.event-full-modal .modal-body
  max-width 95vw

.camera-diagnostic
  cursor pointer

.diagnostic-version-popover
  .version-block
    margin-bottom 5px
    .version-value
      white-space nowrap

.diagnostic-upload-firmware-modal
  .confirm-title
    margin-bottom 10px
  .table
    margin-bottom 0

.diagnostic-upgrade-modal
  .confirm-title
    margin-bottom 10px
  .table
    margin-bottom 0
    p
      margin-bottom 0
  .stop-on-error
    margin-right 5px
    display inline-block

.diagnostic-search-field
    width: 100%
