/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-user-icon__avatar,
.ks-user-icon__no-avatar
    color: #939b9d
    display: block
    width: 100%
    height: 100%
