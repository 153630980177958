/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for event_faces_modal
**/

.event-faces-modal
    &__title
        display: inline-block
        font-size: 24px
        line-height: 1.42857143
        max-width: 95%
        vertical-align: bottom

.event-faces-modal-slider
    display: inline-block
    margin-right: 20px
    font-size: 20px
    vertical-align: text-bottom
    &__arrow
        display: inline-block
        cursor: pointer
        &.mod-arrow-prev
            padding-right: 7px
        &.mod-arrow-next
            padding-left: 7px
        &.is-disabled
            cursor: default
            opacity: 0.6
    &__counter
        display: inline-block
        font-weight: bold

.event-faces-modal-row
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    padding-top: 2px
    padding-left: 30px
    width: 100%
    height: 73vh
    @media(max-width 991px)
        .event-faces-modal-col
            height: 300px
            margin-bottom: 20px
    &__item
        position: relative
        box-shadow: 1px 1px 1px 0 grey
        height: 100%

.event-faces-modal-not-available
    display: flex
    align-items: center
    justify-content: center
    font-size: 24px;
    color: #666;
    font-weight: 700
    background: #dcdcdc
    border: 4px solid #eee
    height: 100%
    width: 100%