/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Tripwire style
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'colors'
@require 'kp/player/common/conf'

.va-tw .va-tw-bgd
    visibility hidden
    fill-opacity 0

.va-tw .va-tw-side
    stroke $clRule
    stroke-width 4px

.va-tw .va-tw-point, .va-tw .va-tw-arrow
    visibility hidden

.va-tw.editable .va-tw-bgd
    visibility visible

.va-tw.editable .va-tw-side
    stroke-width 6px
    cursor move

.va-tw.editable .va-tw-point
    visibility visible
    fill $clRule
    cursor pointer

.va-tw.editable .va-tw-arrow
    visibility visible
    fill-opacity 0
    cursor pointer
    stroke $clRule
    stroke-width 2px

.va-tw .va-tw-arrow.va-tw-selected
    fill-opacity 0.8
    fill $clRule

.va-tw.mod-pf
    .va-tw-side
        stroke: $event.clPfSearch
    .va-tw-point
        fill: $event.clPfSearch
    .va-tw-arrow
        stroke: $event.clPfSearch
    .va-tw-arrow.va-tw-selected
        fill: $event.clPfSearch