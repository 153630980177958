/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for face card footer component
 *


 *
 * Maxim Rodionov <max.rodionov@synesis.ru>
**/
$mutedTextOpacity = 0.7;

.fc-footer
    display flex
    align-items center
    justify-content center
    height: 100%
    flex-direction: column
    padding 5px 10px
    overflow: hidden
    &.with-badge
        padding-right: 20px
    &.expanded
        align-items: flex-start
        justify-content: flex-start
        padding-top: 30px
        padding-left: 20px
        &.is-ready
            overflow-y: auto

.face-card.mod-in-search
    .face-card__footer
        height: $faceCard.searchFooterHeight

.fc-footer__text-cont
    width: 100%
    .fc-footer.expanded &
        margin-bottom: 10px

.fc-footer__title
    display flex
    align-items: center
    justify-content: center
    max-width: 100%
    .fc-footer.expanded &
        justify-content: space-between
        width: 100%

.fc-footer__title-text
    max-width: 100%
    flex-shrink 1
    min-width 1px
    &.mod-muted
        opacity $mutedTextOpacity
    &.mod-link
        cursor: pointer

.fc-footer__search-similarity
    margin-left 5px
    opacity: $mutedTextOpacity
    cursor: default
    font-size: 12px
    flex-shrink 0

.fc-footer__subtitle
    text-align: center
    font-size 12px
    max-width: 100%
    .fc-footer.expanded &
        text-align: left

.fc-footer__badge
    display: flex
    justify-content: center
    align-content: center
    align-items: center
    position: absolute
    top: 24px
    right: 4px
    font-weight: 600
    font-size: 12px
    width: 22px
    height: 22px
    border-radius: 50%
    background: #31708f
    color: #fff
    cursor: pointer
    .fc-footer.expanded &
        font-size: 22px
        background-color: transparent
        top: 5px
        right: 12px

.fc-footer__similarity
    margin-top 4px

.fc-footer__data
    height: 100%
    width: 100%

.fc-footer__data-field
    margin-bottom: 8px

.fc-footer__data-field-name
    font-size: 80%

.fc-footer__data-field-link
    text-decoration: underline
    &:hover
        text-decoration: none
