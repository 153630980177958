/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
@require 'style/settings/colors'


.thumbnail //patch for bootstrap
    transition: none  !important
    border-radius: 0

.thumbnail.mod-in-sb
    margin: 1px 0 0 1px
    cursor: pointer
    margin-bottom: $sidebar.gapBottom

.thumb-element
  padding-right 8px
  padding-left 8px

.thumb-element .thumbnail
  display inline-block
  width 100%
  margin 3px 0
  box-shadow: rgba(0,0,0,.3) 0 1px 3px;
  border: none!important;

.thumb-selectable
  cursor: pointer

.foldtr
  position: relative
  border-width: 2px
  border-color: $selectionColor !important

.foldtr-selection
  border 1px solid $selectionColor
  outline: 1px solid $selectionColor

.thumbnail.mod-fixed
    height 340px

.thumbnail.mod-sm
    height 212px

.thumbnail.mod-fixed .thumb-btn
    position absolute
    bottom 15px
    color $fontColor--light
    display inline-block
    padding 0
    font-size 18px
    width 26px
    height 26px
    line-height 1.33
    background-color #fff
    &:nth-of-type(1)
        right 50px
    &:nth-of-type(2)
        right 15px
    &:hover
        color $fontColor--onhover
    &.mod-disabled
        opacity 0.4


.thumbnail-img-cont
    position relative
    //width 280px
    height 190px
    display flex
    justify-content center
    align-items center
    background-color white
    max-width 100%

.thumbnail-img-cont.mod-with-snap
    background-color $snapshotBgColor

.thumbnail-img-cont.mod-no-snap
    background-color white

.thumbnail-img-cont.mod-inner-padding
    padding 5px 10px

.thumbnail-img
    max-height 100%
    max-width 100%

.thumbnail-img.mod-stretch
    width 100%

.thumbnail-img.mod-auto
    width auto !important //override bootstrap width 100% (div div.row div div.thumbnail img)

.thumbnail-img-cont-wrap
    top 0
    position absolute
    width 100%
    height 100%
    background-color rgba(200,200,200,0.6)
    display flex
    justify-content center
    align-items center
    flex-direction column

.svg-icon-cont.mod-grey
    color $baseColor--ultraLight
