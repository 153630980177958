/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.statistics-view

  .c3-axis.c3-axis-x text,
  .c3-axis.c3-axis-y text,
  .c3-legend-item text
    font-size 13px

  .stats-datepicker
    cursor pointer
    width 100px
    text-align center

  .charts-title
    text-align center

  .charts-icon-container
    max-height 50px

  .charts-icon
    width 40px
    padding-top 10px
    padding-right 10px

@require './signaling_monitor.styl'
