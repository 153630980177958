/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * goto_event.styl
 *
 * Description : style for next.perv event button
 *
 * Created by Maxim Rodionov on 26/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'

.kp-goto
    &-prev-ev
        position absolute
        top $toolBoxTop
        left $prevEventLeft
    &-next-ev
        position absolute
        top $toolBoxTop
        left $nextEventLeft
