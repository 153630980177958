/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for facelist_faces component
**/

.facelist-faces
    display flex
    flex-wrap wrap
    padding-top 2px
    padding-left 30px
    align-content flex-start
    &__item
        position relative
        background-color #fff
        user-select none
        box-shadow 1px 1px 1px 0 grey
        height 100%

.faces-all-faces-menu
    border-bottom: 1px solid #ddd
    margin-bottom: 10px

.facelist-dropdown-print
    display: inline-block
    margin-bottom: 0
    .dropdown-menu
        width: auto
    .value-element
        a
            display: block
            color: #333
        &:hover, &:focus, &:active
            a
                text-decoration: none
                color: #333
        &:hover
            a
                color: #fff