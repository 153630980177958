/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for sidebar items list
**/
$activeFontColor = #555
$activeBgdColor = #eee
$bootstrapLinkColor =  #428bca

.sidebar-list
    list-style: none
    padding-left: 0

.sidebar-list__item
    position: relative
    display: block
    cursor: pointer

.sidebar-list-a
    min-height: 40px
    padding: 5px
    position: relative
    display: flex
    justify-content: flex-start
    align-items: center
    text-decoration: none
    &__hover-ctrls
        display: none
        flex-shrink: 0 // prevent icon buttons shrink
        margin-left: auto

.sidebar-list-a
    &:hover, &:active, &:focus
        text-decoration: none
    &:focus
        color: $bootstrapLinkColor
    &:hover
        color: $activeFontColor
        background-color: $activeBgdColor
    &.is-active
        background-color: $activeBgdColor
        cursor: default
        color: $activeFontColor

.sidebar-list-a:hover,.sidebar-list-a.is-active
    .sidebar-list-a__hover-ctrls
        display: block
