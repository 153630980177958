/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * conf.styl
 *
 * Description : common variables
 *
 * Created by Maxim Rodionov on 05/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */

$playerBgd = #1B1B1B;

$ctrlsTransition = 0.1s ease-in
$clBtnBgd = #444
$clBtnHoverBgd = #6a6a6a
$clBtnActiveBgd = #487da9
$btnHeight = 24px // used by icon_btn to proper align icons
$timelineArchiveNA =  #444
$timeLineArchiveA = #c0c0c0
$clLive = #c23433
$clWarning = #d58512

$clDialogBgd = rgba(#111111, 0.8) // background for some dialog dropdowns, and tooltips
$clDialogInput = #4C4C4C

$clText =  #fff
$clMutedText = #737373

$toolBoxHeight = 125px
$toolBoxBottom = 5px
$toolBoxTop = 5px
$toolBoxRight = 8px
$toolBoxLeft = 8px

$tlHeight = 60px
$tlTop = 30px

$iconSize = 24px
$iconMarginLeft = 5px
$smallIconSize = 18px

// used by event-shower and should be used another in-front widthet
// should work in fullscreen & in angular-bootstrap modal (z-index=1050)
$inFrontZIndex = 500000


// toolbox button positions keep in sync with icons width
$prevEventLeft = $toolBoxLeft
$playpauseLeft = $prevEventLeft + $iconSize + $iconMarginLeft
$nextEventLeft = $playpauseLeft + $iconSize + $iconMarginLeft
$volumeLeft = $nextEventLeft + $iconSize + $iconMarginLeft
$rateLeft = $volumeLeft + $iconSize + $iconMarginLeft
$pfSearchCtrlsLeft = $rateLeft + 2*$iconSize + 3*$iconMarginLeft + 25px

$event = {
    clLow: rgb(92, 184, 92),
    clMiddle: rgb(91, 192, 222),
    clHigh: rgb(240, 173, 78),
    clCritical: rgb(217, 83, 79),
    clPfSearch: #ffeb3b
}

m-user-select($val)
    -webkit-user-select: $val;  // Chrome all / Safari all
    -moz-user-select: $val;     // Firefox all
    -ms-user-select: $val;      // IE 10+
    user-select: $val;          // Likely future


// timehint and archive snapshot
$timehintTransition = opacity 0.5s ease-in
$timehintTop = -24px
$timehintZindex = 100
