/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-comments
    padding: 15px
    background-color: #fafafa
    border: 1px solid #dbdbdb
    border-left-width: 2px
    &:hover, &.is-selected
        border-left-color: #0084ff

.ks-comments__header
    cursor: pointer

.ks-comments__header-row
    display: flex
    font-size: 11px

.ks-comments__header-row-second
    margin: 5px 0

.ks-comments__header-icon
    width: 19px
    height: 19px

.ks-comments__type
    margin-left: 10px

.ks-comments__resolved
    margin-left: 10px

.ks-comments__ctrls
    margin-left: auto

.ks-comments__id-value
    font-weight: bold

.ks-comments__date
    margin-left: auto

.ks-comments__events
    width: 100%
    margin-bottom: 20px

.ks-comments__comments-header
    display: flex
    align-items: center
    font-size: 12px

.ks-comments__comments-title
    cursor: pointer

.ks-comments__comments-add
    margin-left: auto
    background-color: rgba(0, 0, 0, 0.06)
    border-radius: 8px
    padding: 1px 4px
    cursor: pointer
    color:  #878787
    font-weight: 400

.ks-comments__comment
    background-color: white
    border: 1px solid #d7d7d7
    border-radius: 4px
    padding: 10px
    margin: 15px 0

.ks-comments__comment-header
    display: flex
    align-items: center
    font-size: 11px
    margin-bottom: 5px

.ks-comments__new-comment
    margin: 10px 0

.ks-comments__new-comment-text
    resize: none
    width: 100%

.ks-comments__comment-user-icon
    width: 17px
    height: 17px

.ks-comments__comment-date
    margin-left: 7px

.ks-comments__comment-ctrls
    margin-left: auto
    display: none
    .ks-comments__comment:hover &
        display: block

.ks-comments__comment-ctrls-icon
    cursor: pointer
    &:hover
        cursor: pointer
        color: #000

.ks-comments__comment-text
    font-size: 13px
    color: #555555
