/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for face card component
 *


 *
 * Maxim Rodionov <max.rodionov@synesis.ru>
**/
@require 'application/conf'

$faceCard = {
    footerHeight: 50px, // if change see also 'FACE_CARD_PAD_HEIGHT' variable in flex_field_directive!
    searchFooterHeight: 70px, //if change see also 'FACE_CARD_SEARCH_FOOTER_HEIGHT' in js
    headerHeight: 19px,
    iconSize: 17px,
    mutedTextOpacity: 0.7
}
$faceCard.iconBgdColor = rgba(0, 0, 0, 0.25)
$faceCard.ctrlSize = $faceCard.iconSize + 20px


m-flex-with-space-between()
    display: flex
    justify-content: space-between


.face-card
    height 100%
    color: #fff  // color inherited by child text nodes
    font-size: 11px
    &__img-cont
        position: absolute
        width 100%
        top: 0
        bottom: $faceCard.footerHeight
        overflow hidden
        background-color $snapshotBgColor
    &__footer
        position: absolute
        width: 100%
        height: $faceCard.footerHeight
        bottom: 0
        z-index: $conf.zindex.faceCardHeader
        background-color rgba(76, 76, 76, 1)
        font-size 14px
        user-select text
        transition: height 0.1s ease-out
        &.expanded
            height: 100% !important
    a
        color: inherit // all links will be white
        cursor: pointer;


.face-card.mod-not-clickable
    .face-card-media-holder
        cursor: default

.face-card.mod-in-search
    .face-card__img-cont
        bottom: $faceCard.searchFooterHeight

.face-card__img-cont:hover
    .face-card-ctrls.mod-top-left
    .face-card-ctrls.mod-top-right
        top: 0
    .face-card-ctrls.mod-bottom
        bottom: 0


/*
 *  Misc
**/
.face-card-media-holder
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    cursor: pointer
    justify-content: space-around
    align-items: flex-end  // all images moved down if no "mod-centered-cont"

.face-card-img
    max-height 100%
    max-width 100%
    width: 100%
    height: 100%
    object-fit: contain

.face-card-media-holder.mod-centered-cont // used to hold spin block or image
    align-items center

.face-card-top-ctrls
    position: absolute
    pointer-events: none  // to allow image click
    top: 0
    left: 0
    width: 100%
    height: $faceCard.ctrlSize
    overflow: hidden
    > div
        pointer-events: auto

.face-card-ctrls
    position: absolute
    width: $faceCard.ctrlSize
    height: $faceCard.ctrlSize
    padding: 5px
    transition all 0.4s ease
    &.mod-top-left
        top: - $faceCard.ctrlSize
        left: 0
        background: radial-gradient(circle farthest-side at top left, $faceCard.iconBgdColor, transparent)
        border-bottom-right-radius: 10px
    &.mod-top-right
        top: - $faceCard.ctrlSize
        right: 0
        background: radial-gradient(circle farthest-side at top right, $faceCard.iconBgdColor, transparent)
        border-bottom-left-radius: 10px
    &.mod-bottom
        width: 100%
        bottom: - $faceCard.ctrlSize
        background: linear-gradient(to top, $faceCard.iconBgdColor, transparent);
        m-flex-with-space-between()
        align-items: flex-end
        z-index 2
    &.mod-disable
        display: none

.face-card-warn-icon
    position: absolute
    left: 0
    top: 0

.face-card-icon
    display: block  // to use in <a> tags
    cursor: pointer
    width: $faceCard.iconSize
    height: $faceCard.iconSize
    filter: drop-shadow(1px 1px rgba(0, 0, 0, 0.6))
    &.mod-space-right
        margin-right: 10px

.face-card-carousel
    m-flex-with-space-between()
    font-weight bold
    text-shadow 1px 1px 1px rgba(0,0,0,.5)
    &__counter
        width: 40px // calculated  for "xx / yy" edge case
        text-align: center
    &__arrow
        cursor: pointer

.face-card-carousel__arrow.is-disabled
    cursor: default
    opacity: 0.6

.face-card-icons-gp
    display: flex
    justify-content: flex-start
    width: 45px  // the same width of right and left bnt group used to centered face-card-carousel
    &.mod-right
        justify-content: flex-end