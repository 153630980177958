/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'kp/player/common/conf'


.kp-bypass-toggle-btn
    &.mod-enabled:not(.is-active)
        background-color: $clWarning

.kp-bypass-actions
    margin-right: 15px

.kp-bypass-action.mod-no-border-radius
    border-radius: 0

.kp-bypass-until
    flex: 1 100%
    text-align: center
