/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.iamas-modal-body
    max-height: 70vh !important  // default 80vh but we need compensate a header search bar
    min-height: 50vh

.iamas-modal-body__results
    margin-top: 4px

.iamas-modal-body__item
    display: block
    margin: 8px 0

.iamas-search-form
    padding: 6px 0 0 0
    display: flex
    align-items: flex-start
    justify-content: flex-start
    // height: 60px  // height of maximum element in form to prevent jumps when change search mode
    position: sticky
    top: 0
    background-color: white

.iamas-search-form__el
    margin-right: 4px
    &.mod-dateinput
        flex-shrink: 0

.iamas-footer-checkbox
    display: inline-block
    margin-right: 16px
