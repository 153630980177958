/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-incident-event-title
    display: flex
    font-size: 13px
    color: #333333

.ks-incident-event-title__badge
    cursor: pointer
    min-width: 20px
    border-radius: 10px
    margin: 0 5px 0 0
    span
        vertical-align: middle

.ks-incident-event-title__text
    font-size: 13px
    color: #333333
