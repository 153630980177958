/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Adara Belaya <adara.belaya@synesis.ru>, Synesis LLC www.synesis.ru.
 */
 @require 'style/settings/colors'
 @require 'style/settings/z_index'
 @require 'style/settings/sizes'

.ks-sidebar__wrapper
    display: flex
    height: s('calc(100vh - %s)', $navHeight) //minus nav height

.ks-sidebar,
.ks-sidebar__content,
.ks-sidebar__hiddable
    display: flex
    flex-direction: column

.ks-sidebar
    background-color: $sidebarBgColor
    flex: 0 0 $sidebar.widthClosed
    width: $sidebar.widthClosed
    justify-content: flex-end
    height: 100%
    &.is-open
        flex: 0 0 $sidebar.widthOpened
        width: $sidebar.widthOpened
        justify-content: space-between
    &.mod-large-width.is-open
        flex: 0 0 $sidebar.largeWidthOpened
        width: $sidebar.largeWidthOpened

.ks-sidebar__scrollable,
.ks-sidebar__content
    overflow-y: auto
    overflow-x: hidden

.ks-sidebar__hiddable
    height: s('calc(100% - %s)', $sidebar.heightFooter)
    &.is-hidden
        display: none

.ks-sidebar__content
    width: 100%

.ks-sidebar__footer
    display: flex
    border-top: $sidebarBorder
    background-color: $sidebarFooterBgColor
    height: $sidebar.heightFooter
    cursor: pointer
    z-index: $zIndex.sidebarFooter

.ks-sidebar__toggle-btn
    margin: auto
    color: $sidebarToggleBtnBgColor

.ks-sidebar__section
    margin-bottom: $sidebar.gapBottom

.ks-sidebar__container
    padding: $sidebar.sidePadding
    padding-bottom: 0
