/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * tooltip.styl
 *
 * Description : custom style for jquery-ui tooltip
 *
 * Created by Maxim Rodionov on 09/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

.kp-player-tooltip
    padding 3px
    color $clText
    font-size 12px
    background $clDialogBgd
    box-shadow none
    border none
    border-radius 2px
    z-index 10000000000000 // to work in fullscreen mode
