/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
@keyframes receipt-appear {
    0% {
        opacity: 0
        transform-origin: 50% 0
        transform: scale(.2) translate(0, -200%);
      }

      100% {
        opacity: 1;
        transform-origin: 50% 0
        transform: scale(1) translate(0, 0)
      }
 }

.receipts
  height 100%
  overflow-y auto

.receipt
  color rgb(51, 51, 51)
  background rgb(255, 255, 255)
  margin 10px auto
  width 90%
  padding 15px
  cursor pointer
  user-select none
  animation: receipt-appear 1s
  &__header
      text-align center
      font-weight bold
      margin-bottom 5px
      &:after
          content: '********************************************'
          display: block

.receipt__list
  max-width: 100%
  padding: 0
  overflow-x: hidden
  list-style: none
  font normal normal normal 13px/17px Helvetica, Arial, sans-serif

.receipt__list li
    textarea
        width: 100%
        resize: none
        font-size: 9px
        border: 0
        padding-top: 15px
        height: 100%
        font-family: monospace
        &:active, &:focus
            outline: none
            border: 0
    &:before
      float: left
      width: 0
      white-space: nowrap
      content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';

.receipt__expand-btn
    width: 100%
    margin-top: 15px
    background: transparent
    opacity: 0.7
    border: 0
    text-align: center
    cursor: pointer
    &:active, &:focus
        outline: none
        border: 0
    &:hover
        opacity: 1

.receipt__dropdown
    height: 0
    overflow: hidden
    &.expand
        height: 212px

.receipt__item-no-dots
    &:before
        display: none

.receipt__list span:first-child
    padding-right: 0.33em
    background-color: white

.receipt__list span + span
  float: right
  padding-left: 0.33em
  background-color: white
