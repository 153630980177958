/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.errors-view
  .error-desc
    text-align center
    margin-top 5%
    .offline
      color #d9534f
    div
      color #555
      font-size 5em
      font-weight bold
