/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'style/settings/sizes'

$btnColor = #777
$alarmSidebarWidth = 300px

.org-alarms-toolbar-btn-badge
    .badge
        margin-left: -15px

.org-alarms-toolbar-btn-sound
    bottom: -16px
    left: -17px
    width: 0

.org-alarms-toolbar-btn-sound-mute
    width: 18px
    height: 18px
    border-radius: 50%
    padding: 3px !important

.alarms-view
    .queues-sidebar-wrapper
        .ks-sidebar__hiddable.ks-sidebar__scrollable
            padding-top: 0
            padding-left: 0

    .incidents-sidebar-wrapper
        &.ks-sidebar__wrapper
            flex-direction: row-reverse
        .ks-sidebar__hiddable.ks-sidebar__scrollable
            padding-top: 0

    .queues-sidebar-wrapper, .incidents-sidebar-wrapper
        & > .ks-sidebar
            border: 1px solid #cecece
            &.is-open
                width: 390px
                flex: 0 0 390px
        .ks-sidebar__hiddable
            background-color: white
        .ks-sidebar__fixed
            border-bottom: 1px solid #e2e2e2
        & > .ks-sidebar__content
            background-color: #f2f2f2

.alarms-view .alarms-view__noevents
    text-align: center
    margin-top: 5%
    color: #555
    font-size: 5em
    font-weight: bold

.alarms-view__queues, .alarms-view__incidents
    &:first-child
        margin-top: 12px
    &:last-child
        margin-bottom: 12px

.alarms-view__incidents
    padding: 0 15px

.alarms-view__incident
    margin-bottom: 15px

.alarms-view__events-wrapper
    display: flex
    flex-direction: column

.alarms-view__events-toolbar
    height: 70px
    flex: 0 0 70px
    display: flex
    padding: 0 36px
    border: 1px solid #ccc
    border-left: none
    border-right: none
    flex-wrap: wrap

.alarms-view__events-toolbar-content
    display: flex
    width: 100%
    align-items: center

.alarms-view__events-toolbar-right
    margin-left: auto

.alarms-view__events-toolbar-btn
    border-radius: 4px
    border: 1px solid #cfcfcf
    background-color: white
    background-image: linear-gradient(0, rgba(0, 0, 0, 0.08) 0, rgba(255, 255, 255, 0.08) 100%);
    color: #555555
    font-size: 12px
    padding: 8px

    &:hover
        opacity: .80
    &.mod-primary
        background-color: #0078ff
        background-image: linear-gradient(0, rgba(0, 0, 0, 0.08) 0, rgba(255, 255, 255, 0.08) 100%)
        border-color: #0078ff
        color: white
    &[disabled]
        opacity: .65

.alarms-view__events-toolbar-btn-icon
    width: 15px
    height: 15px
    display: inline-block
    vertical-align: middle

.alarms-view__events-toolbar-btn-image
    cursor: pointer
    display: inline-block
    width: 24px
    height: 24px
    margin-right: 13px
    margin-left: 5px
    color: $btnColor
    vertical-align: middle

.alarms-view__events-toolbar-reverse-events
    margin-right: 13px
    &.mod-disabled
        color: #999
        cursor: default

.alarms-view__events-toolbar-reverse-icon
    display: block
    width: 24px
    height: 24px
    cursor: pointer

.alarms-view__events-scrollable
    overflow-y: auto
    overflow-x: hidden
    padding: 10px 36px

.alarms-view__events
    display: flex
    justify-content: flex-start
    flex-wrap: wrap

.alarms-view__event
    margin-bottom: 31px
    margin-right: 20px
