/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.locations-dropdown
  $locationDropdownWidth = 200px

  .dropdown-toggle
    width $locationDropdownWidth
    text-align left

  .locations-dropdown-title
    display block
    overflow hidden
    width $locationDropdownWidth - 40
    text-overflow ellipsis

  .locations-dropdown-menu-row
    display block
    overflow hidden
    text-overflow ellipsis

  .dropdown-menu
    min-width $locationDropdownWidth
    max-height 400px
    width $locationDropdownWidth + 200
    overflow-y auto
    .pager-ctrls
        padding 5px 0 10px

  .ks-location-status-icon
    font-size 9px

.nav > li .toolbar-icon.downloadjobs-toolbar-icon
  padding 4px 5px

.brand-img
  height 20px
  width auto
