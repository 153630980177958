/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 *player volume style
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'

.kp-volume__ctrls
    top $toolBoxTop
    left $volumeLeft
    position absolute
    height 40px
    width $iconSize
    transition width 0.1s

.kp-volume__btn
    position absolute
    top: 0
    left 0

.kp-volume__icon
    height: 100%

.kp-volume__slider-cont
    position: absolute

.kp-volume__slider
    height 100%
    width 0
    transition width 0.1s
    border none

.kp-volume__slider .ui-slider-handle
    cursor pointer
    background-color white
    height 7px
    width 0
    border-radius 50%
    border none
    outline none
    margin-left -2px
    box-shadow -1px 0 1px 0 rgba(0,0,0,0.2)
    top -1px

.kp-volume__slider .ui-slider-range
    background-color white
    background-image none

.kp-volume__ctrls.mod-expand
    width 91px
    & .kp-volume__btn
        width: 100%
        border-radius: 30px
        padding: 0
        background-color: $clBtnActiveBgd
        z-index 1
    & .kp-volume__icon
        width: $iconSize
        height: $iconSize
        padding: 5px
        float: left
    & .kp-volume__slider-cont
        width 57px
        top: 42%
        left: $iconSize
    & .kp-volume__slider .ui-slider-handle
        width 6px
    & .kp-volume__slider.ui-slider-horizontal
        width 100%
        cursor pointer
        height 5px
        background-color $playerBgd
        border none
        background-image none
        &.is-moving
            transition opacity 0.1s
            opacity 0
