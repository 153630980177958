/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
// Backport from 3.x version
$spinnerHeight=25px

.u-is-loading
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed !important
    position: relative

.u-is-loading.mod-with-spinner
    min-height: $spinnerHeight
    &:after
        content: ''
        position: absolute
        top: 50%
        left: 50%
        width: 25px
        height: $spinnerHeight
        margin-top: -10px
        margin-left: -10px
        border-radius: 50%
        border: 2px solid rgba(0, 0, 0, .3)
        border-top-color: #357ebd
        z-index: $conf.zindex.loadingSpinner
        animation: is-loading-spinner-animation .6s linear infinite

@keyframes is-loading-spinner-animation
    to {transform: rotate(360deg)}
