/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for pager controls
 *


 *
 * Maxim Rodionov <max.rodionov@synesis.ru>
**/
@require 'application/conf'

.pager-ctrls
    display: flex
    justify-content: space-between
    align-items: center
    &__gp
        margin: 0 10px
    &__range-info
        min-width: 70px
    &__page-info
        min-width: 50px
        display: inline-block
        text-align: center

.pager-ctrls.is-hidden
    visibility: hidden
