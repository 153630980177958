/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * creation_card
 *
 * Description : creation cards styles
 *
 * Created by Adara Belaya on 9/06/15
 *


 */

/*names will be fixed when integrate this in kp-plugins*/

.avatar-placeholder
    display flex
    background-color $superUltraLightColor
    border 2px dashed $borderColor--base
    border-radius 6px
    color $fontColor--light
    cursor pointer
    text-align center
    text-shadow 0 1px 1px #fff
    transition all 0.3s ease
    opacity 0.7
    //difference from kipod x
    height 329px
    max-width 100%
    max-height 100%
    justify-content: center
    align-items: center

.avatar-placeholder:hover //difference from kipod x selector
    background $topPanelActiveColor
    border-color #888
    color $fontColor--base
    text-shadow none

.avatar-placeholder-center
    position relative

.avatar-placeholder-add-btn
    margin auto auto
    background #ececec
    border 1px solid #cfcfcf
    border-radius 50%
    width 44px
    height 44px
    display flex
    box-shadow 0 0 2px #fff inset

.avatar-placeholder__icon
    width: 12px
    height: 14px
    margin: auto auto
