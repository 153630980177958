/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.modal-open .modal.password-confirmation div.modal-dialog
  width 600px

.avatar-container
    cursor pointer
    img
        width: 100%

.org-priority-level-label
  margin-top 5px
  margin-right 4px
  margin-left 2px
  width 10px
  border-radius 5px
  height 10px
  display: inline-block

.org-select-priority-control
  .ui-select-toggle
    width 100%
  .ui-select-search
    width 100%
  .ui-select-match.ng-hide
    display block !important
  .ui-select-match-text
    width auto

.org-vpnlink-group
  width 100%

.org-thumbnail-wrapper.thumb-element
  display inline-block

.btn-default.zeroclipboard-is-hover
  color #333
  background-color #e6e6e6
  border-color #adadad

.btn-default.zeroclipboard-is-active
  color #333
  background-color #fff
  border-color #ccc

._prevent-shift-footer //prevent error messages shift footer TBL-2415
    min-height: 601px
