/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.incident-modal__user-icon
    width: 25px
    height: 25px
    display: inline-block
    vertical-align: middle

.incident-modal__user-name
    display: inline-block
    vertical-align: middle

.incident-modal__type-icon
    width: 19px
    height: 19px
    display: inline-block
    vertical-align: middle

.incident-modal__events-header
    cursor: pointer
    label
        cursor: pointer

.incident-modal__events
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    flex-direction: row

.incident-modal__event
    width: 180px
    margin: 0 15px 10px 0
    border: none !important
    border-radius: 2px
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19)
    .ks-incident-event__image
        height: 130px

.incident-modal__comments-header
    cursor: pointer
    label
        cursor: pointer

.incident-modal__comments-add
    display: inline-block
    background-color: rgba(0, 0, 0, 0.06)
    border-radius: 8px
    padding: 1px 8px
    cursor: pointer
    color:  #878787
    font-weight: 400

.incident-modal__new-comment
    margin-bottom: 10px

.incident-modal__new-comment-text
    resize: none
    width: 100%

.incident-modal__comment
    padding: 15px
    margin-bottom: 5px
    background-color: #f9f9f9
    border: 1px solid #d7d7d7
    border-radius: 4px

.incident-modal__comment-header
    margin-bottom: 10px
    display: flex

.incident-modal__comment-user-icon
    width: 24px
    height: 24px
    display: inline-block

.incident-modal__comment-user-text
    font-weight: bold
    margin-left: 5px

.incident-modal__comment-text
    padding-left: 30px
    color: #8f9080

.incident-modal__comment-date
    margin-left: 10px

.incident-modal__comment-ctrls
    margin-left: auto
    display: none
    .incident-modal__comment:hover &
        display: block
.incident-modal__comment-ctrls-icon
    &:hover
        cursor: pointer
        color: #000
