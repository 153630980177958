/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * novideo.styl
 *
 * Description:  style for novideo widget
 *
 * Created by Maxim Rodionov on 27/05/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
.novideo
    width 100%
    height 100%
    // display none
    text-align center
    background-color #000

.novideo-text
    position absolute
    top 50%
    left: 50%
    transform: translate(-50%, -50%)
    padding 2px 15px
    color #fff
    background-color #c23433
    font 36px Arial, Helvetica, sans-serif
    -webkit-border-radius 5px
    -moz-border-radius 5px
    border-radius 5px
    // border 2px solid #000
    box-shadow: 0 0 25px 15px #000;
    opacity: 0.8
