/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for base svg plugin html
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/

.kp-svg-plugin
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    & > svg
        border: yellow

.kp-svg-plugin.is-resizing
    visibility: hidden

.kp-svg-plugin.is-hidden
    display:none

.kp-svg-plugin.is-loading
    opacity: 0.5
    pointer-events: none