/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'style/settings/colors'

.ks-alarms-queue-row
    display: flex
    align-items: center
    padding: 13px 15px

    &.mod-selectable:hover
        background-color: $selectionColor--dark
        cursor: pointer
    &.is-selected
        background-color: $selectionColor--dark
        cursor: default

.ks-alarms-queue-row__operator
    height: 19px
    min-width: 19px
    width: 19px
.ks-alarms-queue-row__operator-content
    width: 100%
    height: 100%

.ks-alarms-queue-row__name
    margin-left: 14px
    width: 100%
    color: #727272

.ks-alarms-queue-row__size
    width: 55px
    min-width: 55px
    margin-left: 5px

.ks-alarms-queue-row__size-badge
    width: 100%
    border-radius: 4px
    background-color: rgba(165, 165, 165, 0.23)
    color: #333333
    font-weight: normal

.ks-alarms-queue-row__status
    width: 19px
    min-width: 19px
    height: 19px
    margin-left: 5px
    .state-icon
        width: 100%
        height: 19px

.ks-alarms-queue-row__ctrls
    margin-left: 5px


.ks-alarms-queue-row.mod-alarm
    .ks-alarms-queue-row__name, .ks-alarms-queue-row__size-badge
        color: #222222
        font-weight: bold
