/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'style/settings/sizes'

$priortyCellWidth = 30px
$priortyLabelSize = 10px

.tickets-view
    height: s('calc(100vh - %s)', $navHeight)
    display: flex
    flex-direction: column
    .no-flex-grow
        flex-grow: 0
    &__table-header
        margin-bottom: 0
        tr>td
            border: 0
    &__wrapper
        flex-grow: 2
        overflow: auto
        padding: 0 5px
    &__priority-cell
        width: $priortyCellWidth
        max-width: $priortyCellWidth
    &__priority-label
        display: inline-block
        width: $priortyLabelSize
        height: $priortyLabelSize
        margin: 0 5px
        border-radius: 50%
        &.mod-red
            background-color #d9534f
        &.mod-yellow
            background-color #f0ad4e
        &.mod-green
            background-color #5cb85c
        &.mod-grey
            background-color #333
.tickets-table
    tbody>tr:nth-child(even), tbody>tr:first-child
        td
            padding: 10px 5px 15px
            border-top: 0