/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * bgd.styl
 *
 * Description : style for plan background widget
 *
 * Created by Maxim Rodionov on 25/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/plan/common/conf'

.kp-plan-bgd
    &-border-rect
        stroke-width 2px
        fill rgba(#333, 0.04)
    &-text
        fill $clPlanBgdText
    &-offline-text
        fill $clErrText
        font-size 14px
    &-offline-bgd
        fill $clError
        opacity 0.8
        /* stylelint-disable-next-line */
        x 6
        /* stylelint-disable-next-line */
        y 6
    &-label
        &-text
            font-size 16px
            fill #333
            text-anchor start
        &-bgd
            fill #fff
            opacity 0.8
            stroke-width 0

.kp-plan-bgd .kp-plan-bgd-text.is-error
    fill $clError

