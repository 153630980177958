@require 'kp/player/common/conf'

.kp-rate__ctrls
    top $toolBoxTop
    left $rateLeft
    position absolute
    height 40px
    transition width 0.1s

.kp-rate__up
    float left
    span
        font-size 18px

.kp-rate__down
    float left
    span
        font-size 18px

.kp-rate__con
    float left
    margin 0 5px
    width 25px

.kp-rate__label
    font-size 13px
    line-height 24px
    text-align center
    padding 0
    margin 0
