/*
 * Copyright (C) 2018 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.camera-selector-title
    max-width: 65%
    .camera-selector-text
        display: inline-block
        width: 100%
        vertical-align: middle
    &>span
        display: block
.mod-camera-selector
    display: flex
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
.camera-selector-pag-ctrl
    margin: 10px 0