/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.search-face
    padding: 0 20px
    &__title
        font-size: 18px
        text-align: center
        margin: 15px 0
    &__cnt
        height: 275px
        width: 100%
        margin: 0 auto 15px
    &__upload
        height: 100%
    &__img-wrap
        text-align: center
        vertical-align: middle
        margin: 20px 0
        height 100%
        position: relative
    &__similarity
        margin: 15px 0
    &__btn
        width: 150px
    &__event
        width: 100%
        height: 100%
    &__face-card
        height 100%
        color: #fff  // color inherited by child text nodes
        font-size: 11px
    &__img-cont
        position: absolute
        width: 100%
        height: 100%
        background: #333
    &__media-holder
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: flex
        justify-content: space-around
        align-items: center
    &__img
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;

.search-face-select-img
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    background-color: #fff
    color: #777
    cursor: pointer
    text-shadow: 0 1px 1px #fff
    border: 2px dashed #ccc
    border-radius: 6px
    transition: all 0.3s ease
    opacity: 0.7
    &:hover
        box-shadow: 0 1px 1px 0 #F6F5F5
        background: #DEDEDE
        border-color: #888
        color: #333
        text-shadow: none
    &__add-btn
        display: block
        width: 44px
        height: 44px
        position: relative
        background: #ececec
        border: 1px solid #cfcfcf
        border-radius: 50%
        box-shadow: 0 0 2px #fff inset
        &:after
            bottom: 0
            left: 0
            right: 0
            color: #b0b0b0
            content: '+'
            display: block
            font-family: sans-serif
            font-size: 33px
            line-height: 33px
            font-style: bold
            position: absolute
            top: 6px
            text-align: center
