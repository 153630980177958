/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.plans-sidebar-img-preview
  height 110px
  background-color #f5f5f5

.plans-no-image-txt
  line-height 110px
  text-align center
