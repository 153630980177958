/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * style of the post fuctum search player plugin
**/
@require 'kp/player/common/conf'

$search = {}
$search.rowMargin = 4px
$search.text = {
    iconHeight: 12px  // font awesome icon height
    iconWidth: 12px
}

.kp-search-bar
    position: absolute
    top: $toolBoxTop
    left: $pfSearchCtrlsLeft
    transition: left 0.3s


.kp-search-text
    position: relative
    &__input
        width: 180px
    &__clear-icon
        display: none
        position: absolute
        right: 8px
        top: (($btnHeight - $search.text.iconHeight) / 2)
        opacity: 0.7
        cursor: pointer

.kp-search-text.has-text
    .kp-search-text__input
        padding-right: ($search.text.iconWidth + 4px)
    .kp-search-text__clear-icon
        display: block;


.kp-search-text__clear-icon:hover
    opacity: 1

.kp-search-bar.is-expand
    // left: 190px


.kp-search-success
    color: #91DE91

.kp-search-error
    color: #F98884
