/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

//special size for face gallery modal, also used in face_form_modal_srv
.modal-mod-fg
    @media (min-width: 850px)
        width: 838px
    @media (min-width: 1060px)
        width: 1040px

.modal-mod-full
    width 90%
    height 90%

.modal-open .modal-player div.modal-dialog
  display flex
  width auto
  align-items center
  justify-content center

.modal-player
  margin 0 0

.modal-body, .modal-frame
  margin 0 3px
  max-height 80vh
  overflow auto

.modal-body
  padding 20px 35px

.modal-player-close
  position absolute
  top -15px
  right -20px
  font-size 15px
  line-height 15px
  cursor pointer

.modal-header
  padding 12px 35px
  position relative

  &.modal-header-collapsed
    height 0
    border none
    min-height 0
    padding 0

.modal-close
    position: absolute
    top: 15px
    right: 13px
