/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for face form modal
 *


 *
 * Maxim Rodionov <max.rodionov@synesis.ru>
**/
$previewImgWidth = 90px
$previewImgHeight = $previewImgWidth
$activePhotoFrame = 5px
$photoMargin = 15px

.face-form__layout
    display: flex
    max-height: 850px

.face-form__add-btn
    margin-top: 30px
    & i
        font-size: 20px

.face-form__icons
    margin-right: 3px

.face-form__fields-col
    height: 100%
    border-right: 1px solid $cl.lightBorderColor

.face-form__preview-cont
    display: flex
    flex-wrap wrap
    align-content: flex-start
    padding: $activePhotoFrame

.face-form__preview-photo
    height $previewImgHeight
    width $previewImgWidth
    margin-right $photoMargin
    margin-bottom $photoMargin
    position relative
    &.is-active
        outline: $activePhotoFrame solid $selectionColor
    &>img //need for kx-img-preview
        width: 100%
        height: 100%
        object-fit: cover //property won't work in IE
        .face-form__preview-photo.mod-new &
            opacity: 0.6

.face-form__preview-photo-veil
    position: absolute;
    width: 100%
    height: 100%
    background-color: #232121
    top: 0
    opacity: 0.5

.face-form__preview-del-label
    display block !important
    width 24px
    height 19px
    cursor pointer
    position absolute
    background-color  rgba(0, 0, 0, 0.3);
    color rgba(255, 255, 255, 0.8)
    padding 1px 8px
    top 0
    right 0
    &:hover
        background-color  rgba(0, 0, 0, 0.5);
        color rgba(255, 255, 255, 1)

/*========================== ZOOM ============================*/

.face-form__zoom-photo-cont
    max-width: 100%
    text-align: center
    margin-bottom: 15px
    & img
        width: 100%
        max-height: 330px
        object-fit: contain
    &.mod-original-res
        max-height: 330px
        overflow: auto
        & img
            max-height: none
            object-fit: none
            width: initial


.face-form__delete-btn
    width: 100%
