/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.settings-logo-container
  width: 200px
  height: 200px
  border: 2px solid #ddd
  padding: 5px 5px
  cursor: pointer

.settings-favicon-container
  width: 48px
  height: 48px
  border: 2px solid #ddd
  padding: 5px 5px
  cursor: pointer

.background-image-container
  cursor pointer

  img
    margin 0
    width auto

.colorpicker .colorpicker-saturation
  width 200px !important
  height 200px !important

.personalization .form-group
    margin-top: 15px
