/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
@require 'style/settings/colors'

.table-row-cell
  max-width 50px

.table-top-panel
  padding-bottom 15px

.table-btn-add
  margin-top 5px
  margin-left 15px
  float right

.table-row
  .table-item-btn-actions
    visibility: hidden
    float right
    margin 0 4px

  .text-muted
    color #999

.table-row.is-active
    background-color: $tableHoverColor

.table-row:&hover .table-item-btn-actions,
.table-row.is-active .table-item-btn-actions
    visibility: visible

.table-fixed
    table-layout fixed
    word-wrap break-word
