/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.ks-checkbox
  display inline-block
  width 15px
  height 15px
  border-radius 3px
  border 1px solid #bfbfbf
  text-align center
  .check
    vertical-align top
    cursor default
