/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * main.styl
 *
 * Description : style for custom event plugin
 *
 * Created by Dmitry Lazurkin on 01/12/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

.kp-custom-ev-start-time
    cursor: pointer
    opacity: 0.8

.kp-custom-ev-level-marker
    width: 13px
    cursor: pointer
    height: 13px
    border-radius: 10px
    margin-left: 3px
    display: inline-block
    opacity: 0.7
    &.mod-low
        background-color: $event.clLow
    &.mod-middle
        background-color: $event.clMiddle
    &.mod-high
        background-color: $event.clHigh
    &.mod-critical
        background-color: $event.clCritical
    &.is-selected
        cursor: default
        opacity: 1
        border: 1px solid white
    &:hover
        opacity: 1

.kp-custom-ev-comment-input
    padding: 0 4px
    height: $btnHeight
    width: 400px //this is for purpose. we want long comment visible
    background: $clDialogInput
    border: 1px solid lighten($clDialogInput, 10%)
