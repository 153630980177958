/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.usergroup-table-container
    padding 0 10px 10px 10px
    font-size 16px
    margin-top: 67px
    overflow-x: hidden
    overflow-y: auto

.select-area
    .user-element-wrapper
        margin-top 5px

.table-row .usergroups-add-btn
    margin 3px

.usergroup-modal
    .modal-body
        height 560px
    .select-area
        height 400px

.usergroup-select-modal
    .filter-wrapper > .input-group
        width 100%
        margin-bottom 8px
    .usergroups-wrapper
        max-height 400px
        .table
            margin-bottom 0
        .table-row:hover
            cursor pointer
        .column-count
            width 300px

// Full specified class name because element is draggable with append to body
.select-area-user-element
    margin-bottom 5px
    transition none
    &:hover
        cursor pointer
        border-color rgba(66, 139, 202, 0.72)
    .user-avatar
        height 100px
        width 100px
        vertical-align middle
        float left
    .user-full-name
        margin-left 110px
        font-weight bold
        font-size 16px
        color #2a6496
    .user-email
        margin-left 110px
        font-size 13px
        color #2a6496
    .user-role
        margin-left 110px
        font-size 13px

    .usergroups-static-panel {
        position: fixed;
        width: 100%;
        padding: 15px 15px 3px;
        background: #f8f8f8;
        border-bottom: 1px solid #ccc;
        z-index: 33;
    }