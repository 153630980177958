/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Timeline SVG style
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'

/*! stylelint-disable selector-max-universal */
.svg-tl.is-download-drag, .svg-tl.is-download-drag *
    cursor: ew-resize !important
/*! stylelint-enable selector-max-universal */

// LABELS
.svg-tl-label
    fill: $clText
    &__marker
        stroke: $clText
        stroke-width: 2px
    // &__text > tspan
    //     dominant-baseline: text-before-edge

.svg-tl-label.mod-short
    .svg-tl-label__text
        opacity: 0.9
    .svg-tl-label__marker
        stroke-width: 1px

m-fade-on-move-animate()
    opacity: 0
    transition: opacity 0.1s ease-in

.svg-tl-fade-on-move
    .svg-tl-label.mod-short .svg-tl-label__text
    .svg-tl-label
    .svg-tl-ev
        m-fade-on-move-animate()



// ARCHIVE
.svg-tl-archive-bgd
    fill: $timelineArchiveNA
    cursor: not-allowed

.svg-tl-archive-gp
    cursor: pointer

.svg-tl-cursor
    cursor: pointer
    fill: #c88619

// .svg-tl-cursor.mod-live
//     fill: $clLive

.svg-tl-archive-seg
    fill: $timeLineArchiveA
    cursor: pointer

.svg-tl-motion-seg
    fill: darken($timeLineArchiveA, 50%)
    cursor: pointer


// EVENTS
.svg-tl-ev
    cursor: pointer
    fill-opacity: 0.7

.svg-tl-ev.mod-gp .svg-tl-ev__gp
    visibility: visible

.svg-tl-ev.is-highlighted
    fill-opacity: 1


$darkenVal = 40%
m-level-color($levelColor)
    .svg-tl-ev__marker
        fill: $levelColor
    .svg-tl-ev__gp
        fill: darken($levelColor, $darkenVal)


.svg-tl-ev.mod-level-low
    m-level-color($event.clLow)
.svg-tl-ev.mod-level-middle
    m-level-color($event.clMiddle)
.svg-tl-ev.mod-level-high
    m-level-color($event.clHigh)
.svg-tl-ev.mod-level-critical
    m-level-color($event.clCritical)
.svg-tl-ev.mod-level-pf-search
    m-level-color($event.clPfSearch)


// HIGHLIGHTED EVENT
.svg-tl-ev-duration
    opacity: 1
    transition: opacity 0.5s ease-in

.svg-tl-ev-duration.mod-opened
    animation: opened-event-blink 1s ease-in-out infinite;

.svg-tl-ev-duration.is-hidden
    opacity: 0

@keyframes opened-event-blink
    0%
        opacity: 0.6
    50%
        opacity: 0.9
    100%
        opacity: 0.6


// DOWNLOAD
.svg-tl-dld
    stroke: $clLive
    fill: $clLive
    &__cursor
        stroke-width: 4px
        cursor: ew-resize
        &__marker
            stroke-width: 2px
            fill: #000
    &__line
        stroke-width: 2px
        stroke-dasharray: 4
    &__drag-zone
        opacity: 0

.svg-tl-dld__drag-zone:hover
.svg-tl-dld__drag-zone.is-in-drag
    opacity: 0.25
    cursor: move

.svg-tl-dld-anchor
    fill: $clLive
    font-size: 14px
    font-weight: bold
    cursor: pointer

.svg-tl-dld-anchor.is-left
    text-anchor: start

.svg-tl-dld-anchor.is-right
    text-anchor: end
