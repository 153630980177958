/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

$cardHeight=120px
$cardPad=4px

.iamas-doc-card
    display: flex
    padding: $cardPad
    cursor: pointer
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)
    &.is-selected
    &:hover.is-selected
        outline: 3px solid rgba(68,140,202,.89)
    &:hover
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.14), 0 3px 6px -2px rgba(0,0,0,0.12), 0 1px 7px 0 rgba(0,0,0,0.2)

.iamas-doc-card__img
    max-height: 100%
    width: $cardHeight - 2*$cardPad
    margin-right: 8px
    flex-shrink: 0
    flex-grow: 0
    align-self: center

.iamas-doc-card__info
    flex-grow: 1

.iamas-doc-card__info-label
    width: 180px
    display: inline-block