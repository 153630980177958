/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-operator-filter
    .ui-select-match-text
        overflow: hidden

.ks-operator-filter__icon
    width: 19px
    height: 19px
    display: inline-block
    vertical-align: middle

span.ks-operator-filter__label
    max-width: calc(100%)
    vertical-align: bottom