/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Main timeline style file
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'
@require './svg_timeline'
@require './events_shower/events_shower'
@require './events_shower/event_thumbnail'
@require './archive_snapshot/archive_snapshot_thumb'
@require './datetimepicker/datetimepicker'

.kp-tl
    position: absolute
    height: $tlHeight
    width: 100%
    top $tlTop
    left 0
    // outline: #959cf4 dashed 3px
    &__svg
        height: 100%
        margin: 5px $smallIconSize 0
        // outline: #ffe744 solid 1px
    &__shift
        position: absolute
        width: $smallIconSize
        height: $smallIconSize
        top: 17px
        // outline: #ffe744 solid 1px
    &__timehint
        visibility: hidden
        position: absolute
        z-index: $timehintZindex
        opacity: 0
        left: 0
        top: $timehintTop

.kp-tl__shift.mod-next
    right: 0

.kp-tl__shift.mod-prev
    left: 0

.kp-tl__timehint.is-visible
    visibility: visible
    opacity: 1
    transition: $timehintTransition

// BUTTONS
.kp-tl-btn-panel
    position absolute
    display: flex
    justify-content: center
    width 100%
    left: 0
    bottom $toolBoxBottom
    &__gp
        margin: 0 10px



// DATEPIKER
// datepicker placed above/below input
// so we need to increase size of hidden input to add some space
$dpInputPseudoMargin = 8px

.kp-tl__dp-input
    top: - $dpInputPseudoMargin
    visibility hidden
    position absolute
    width 75px
    height: $iconSize + (2 * $dpInputPseudoMargin)
