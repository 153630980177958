/*
 * Copyright (C) 2018 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.berlio-check
    &-title
        display: block
    &-textarea
        width: 100%
        height: 300px
        resize: none
        padding: 10px 38px
        font-family: monospace