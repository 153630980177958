/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.login-wrapper
  position absolute
  top 0
  width 100%
  height 100%
  display table

.login-form-wrapper
  display table-cell
  vertical-align middle
  .login-form
    margin-left auto
    margin-right auto
    width 330px
    .login-logo
      margin 0 auto
      text-align center

    .login-logo-img
      width 300px
      height auto

    .form-control
      position relative
      height auto
      -webkit-box-sizing border-box
      -moz-box-sizing border-box
      box-sizing border-box
      font-size 14px

    .login-name
      border-bottom-right-radius 0
      border-bottom-left-radius 0
      margin-top 30px
      margin-bottom 15px

    .login-password
      border-top-right-radius 0
      border-top-left-radius 0

    .login-rememberme
      font-size 12px
      font-weight normal

    .login-copyright
      margin-top 10px
      text-align center
      word-break: break-all

    .login-accessrecovery
      margin-top 10px
      text-align center
