/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * event_thumbnail.styl
 *
 * Description : style for event thumbnail
 *
 * Created by Maxim Rodionov on 12/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

$textHight = 13px
$titleHeight = 2 * $textHight + 2px
$clBackground = #4c4c4c
$fadeDelay = 0.5s

.kp-ev-thumb
    position relative
    background #333
    color: #fff
    font-size $textHight - 2px
    line-height $textHight
    &__media
        position absolute
        width: 100%
        left: 0
        top: 0
        bottom: $titleHeight
        &__time
            position: absolute
            right: 0
            top: 0
            padding: 1px
            text-align: right
            height: 35px
            width: 65px
            background: radial-gradient(ellipse farthest-side at top right, alpha(#000, 0.7), alpha(#000, 0.65), alpha(#000, 0.5), transparent)
        &__level-label
            position: absolute
            width: 100%
            height: 4px
            opacity: 0.6
            bottom: -1px
            transition: opacity 0.4s
    &__title
        position absolute
        width: 100%
        height: $titleHeight
        left: 0
        bottom: 0
        background: $clBackground
        text-align: center
        &__text
            margin: 0 2px
            width: 100%
            max-height: 2 * $textHight
            overflow: hidden
            word-wrap: break-word

.kp-ev-thumb__snapshot
    max-width: 100%
    max-height: 100%

.kp-ev-thumb:hover .kp-ev-thumb__media__level-label
    opacity: 0.9

.kp-ev-thumb
    .kp-ev-thumb__spin-cont
        position: absolute
        width: 100%
        height: 100%
        opacity: 0
        transition opacity $fadeDelay
        &__err-text
            display: none
    .kp-ev-thumb__img-cont
        position: absolute
        width: 100%
        height: 100%
        opacity: 1
        transition opacity $fadeDelay

.kp-ev-thumb.is-loading
    .kp-ev-thumb__spin-cont
        opacity: 1
    .kp-ev-thumb__img-cont
        opacity: 0

.kp-ev-thumb.is-load-fail
    .kp-ev-thumb__spin-cont
        opacity: 1
    .kp-ev-thumb__spin-cont .fa-spin
        display: none
    .kp-ev-thumb__spin-cont__err-text
        display: block
        margin: 0 5px
    .kp-ev-thumb__img-cont
        display: none



.kp-ev-thumb__title__text.is-long-text:after
    position: absolute
    right: 0
    bottom: 0
    width: 30px
    height: 13px
    content: ''
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $clBackground, $clBackground)
