/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
$ultraLightFontColor = #959595;

.search-filter
    position: relative
    padding 5px
    padding-top 0
    padding-bottom 0
    width: 250px

.search-filter__input
    padding-right: 42px

.search-filter__icon
    cursor: pointer
    position: absolute
    z-index: 2

.search-filter__icon-search
    margin: 0
    right: 13px
    top: 0
    width: 20px
    text-align: center
    padding: 10px 0
    color: $ultraLightFontColor
    &:hover //css variables in future
        color: #66afe9
    &:active
        color: $selectionColor
    .search-filter.mod-in-monitor &
        right: 6px

.search-filter__icon-clear
    top: 11px
    right: 29px
