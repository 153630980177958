/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'style/settings/colors'

.ks-alarms-incident-event
    border-radius: 4px
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15)
    background-color: white
    border: 2px solid white
    padding: 8px

    &.is-selected
        border-color: #009cff

.ks-alarms-incident-event__image
    position: relative

.ks-alarms-incident-event__event-image
    height: 230px
    .ks-event-image__id-container
        bottom: 35px !important
    &.mod-not-clickable
        .ks-event-image__image, .ks-event-image__annotation
            cursor: default

.ks-alarms-incident-event__toolbar
    justify-content: space-between
    background-color: rgba(0, 0, 0, 0.61)
    height: 33px
    position: absolute
    bottom: 0
    width: 100%
    padding: 0 13px
    align-items: center
    display: none
    color: white
    z-index: 10

    .ks-alarms-incident-event:hover &
        display: flex

.ks-alarms-incident-event__toolbar-side
    width: 25%

.ks-alarms-incident-event__toolbar-pager
    margin-left: auto
    font-size: 12px
    display: flex
    justify-content: space-between
    cursor: default

.ks-alarms-incident-event__toolbar-pager-title
    width: 40px
    text-align: center

.ks-alarms-incident-event__toolbar-pager-btn
    cursor: pointer
    &.is-disabled
        opacity: 0.6
        cursor: default

.ks-alarms-incident-event__toolbar-btn
    width: 19px
    height: 19px
    color: white
    cursor: pointer
    display: inline-block

    &:not(:first-child)
        margin-left: 14px

    &.mod-right
        margin-left: auto

    &:hover
        opacity: 0.8

.ks-alarms-incident-event__title
    padding: 16px 0 5px 0
