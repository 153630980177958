/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Pavel Morozov <pavel.morozov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * channel_marker.styl
 *
 * Description : style for channel_marker widget
 *
 * Created by Pavel Morozov on 12/05/2020
 *
 * (c) 2020 Synesis LLC.
 * All Rights Reserved.
**/

$channelIconLabelHeight = 20px
$channelIconSize = 36px
$channelLeafletIconSize = 42px

.channel-marker
    cursor: default
    &.leaflet-marker-draggable
        cursor: pointer
    &.mod-editable .channel-marker__rotate-icon
        display: block
    &:focus
        outline: none

.channel-marker__rotate-icon
    width: 16px
    max-height: 16px
    border-radius: 50%
    position: absolute
    top: -11px
    left: 9px
    border: 1px solid #dadada
    background: white

.channel-marker__rotate-small-icon
    width: 10px
    max-height: 10px
    border-radius: 50%
    position: absolute
    top: -6px
    left: 14px
    border: 2px solid #777
    background: white

.channel-marker__icon
    background-color: white
    border: 1px solid hsla(0, 0%, 94%, 1)
    border-radius: 50%
    height: $channelIconSize
    padding: 7px
    width: @height
    box-shadow: 0 0 1px 0.5px rgba(0,0,0,.1), 0 0 0 0.5px rgba(0,0,0,.04)
    &:focus
        outline: none

.channel-marker__leaflet-draggable-icon, .channel-marker__leaflet-fixed-icon
    background-color: #428bca
    border: 2px solid rgba(238,238,238,0.51)
    border-radius: 50%
    height: $channelLeafletIconSize
    width: @height
    padding: 7px
    margin-left: -15px
    margin-top: -15px

.channel-marker__leaflet-fixed-icon
    background-color: #fff
    opacity: 0.75

.channel-marker__label, .channel-marker__leaflet-label
    background-color: rgba(255,255,255,.85)
    color: #333
    left: $channelIconSize + 5px
    max-width: 236px
    padding: 1px 4px
    border-radius: 2px
    position: absolute
    top: ($channelIconSize / 2) - ($channelIconLabelHeight / 2)
    white-space: nowrap
.channel-marker.is-label-hidden &
    display: none

.channel-marker__leaflet-label
    left: $channelLeafletIconSize - 12px
    top: ($channelLeafletIconSize / 2) - ($channelIconLabelHeight / 2)

.ks-is-level-low
  background-color: $event_low_level_color
.ks-is-level-middle
  background-color: $event_middle_level_color
.ks-is-level-high
  background-color: $event_high_level_color
.ks-is-level-critical
  background-color: $event_critical_level_color



