/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Adara Belaya <adara.belaya@synesis.ru>, Synesis LLC www.synesis.ru.
 */
$textMuted = #777
$closeBtnBackground  = #BBBBBB

.u-center-horizontal
    margin-left: auto
    margin-right: auto
    display: block

.u-text-truncate
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.u-no-content-label
  text-align center
  margin-top 10%
  color $fontColor--notFound
  font-size 5em
  font-weight bold

.u-set-clear-icon //in search-filter
    width: 14px
    height: 14px
    padding: 3px
    display: inline-block
    border-radius: 50%
    vertical-align: middle
    color: white
    margin: 0 4px
    background-color: $closeBtnBackground
    svg
        vertical-align: top
    &:hover
        background-color: $textMuted
