/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
// (c) 2015 Synesis LLC.

.player-box .player-sidebar-plugin {
  border-left: 1px #333 solid;
  height: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  top: 0;
  right: 0;
  left: auto;
  width: 301px;
  background: #1B1B1B
}

.player-sidebar-closer {
  position: absolute;
  right: 300px;
  color: #eee;
  padding: 10px 3px;
  /*border: 1px solid #eee;*/
  border-radius: 3px;
  z-index:2005;
  top: 50%;
  margin-top:-20px;
  background-color: rgba(100,100,100, 0.8);
  cursor: pointer;
  font-weight: bold;
}

.player-sidebar-closer.closed {
  right: -3px;
}
