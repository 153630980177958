/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-bill-filter
    flex: 1

.ks-bill-filter__field
    display: inline-block
    vertical-align: middle
    margin-top: 5px

.ks-bill-filter__balance
    margin-left: 20px
    font-weight: bold
