/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * style for archive snapshot
 *
 * (c) 2016 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'

.arch-sn-thumb
    width: 200px
    height: 115px
    background-color: $clDialogBgd
    border: 1px solid #444
    opacity: 0
    position: absolute
    left: 0
    top: -(@height + $timehintTop)
    pointer-events: none
    z-index: $timehintZindex - 1
    &.is-visible
        transition $timehintTransition
        opacity: 1
    & img
        height: 100%
        max-width: 100%
        width: auto
        display: block

.arch-sn-thumb.is-error img, .arch-sn-thumb.is-waiting img
    display: none

.arch-sn-thumb__info-cont
    height: 100%

.arch-sn-thumb__spin
    display: none
    .arch-sn-thumb.is-waiting &
        display: flex

.arch-sn-thumb__err-text
    display: none
    .arch-sn-thumb.is-error &
        display: block
        margin: 0 5px
