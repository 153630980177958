/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.ks-event-image
    display: flex
    align-items: center
    justify-content: center
    background-color: black
    position: relative
    overflow: hidden
    &:hover
        .ks-event-image__slider
            bottom 0
    &.mod-directon-column
        flex-direction: column


.ks-event-image__image
    z-index: 2
    cursor: pointer

.ks-event-image__annotation
    display: inline-block
    position: absolute
    top: 0
    left: 0
    z-index: 2
    cursor: pointer

    .kxObject
        stroke-width: 5

.ks-event-image__no-image
    width: 100%
    cursor: pointer

.ks-event-image .ks-event-image__camera-label
    font-weight: bold
    max-width: 40%
    cursor: pointer
    text-align: left

.ks-event-image__level.priority-level-label
    margin: 2px 0 0 1px

.ks-event-image__id-container
    max-width: 50%
    display: none

.ks-event-image:hover .ks-event-image__id-container
    display: block

.ks-event-image__camera-text, .ks-event-image__date-text, .ks-event-image__time-text
    cursor: pointer

.ks-event-image__spin
    display: flex
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    justify-content: center
    align-items: center
    z-index: 3

.ks-event-image__slider
    display: flex
    justify-content: center
    flex-wrap: nowrap
    width: 100%
    position: absolute
    left: 0
    bottom: -37px
    padding: 10px 0 5px
    z-index: 2
    background: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent)
    transition: all 0.4s ease


.ks-event-image__slider-arrow, .ks-event-image__slider-counter
    color: #fff
    font-weight: bold
    text-shadow: 1px 1px 1px rgba(0,0,0,.5)
    font-size: 11px
    user-select: none

.ks-event-image__slider-arrow
    cursor: pointer
    &.mod-arrow-prev
        padding-right: 7px
    &.mod-arrow-next
        padding-left: 7px

.ks-event-image__slider-arrow.is-disabled
    cursor: default
    opacity: 0.6

.ks-event-image__slider-counter
    text-align: center

.ks-event-image__wrapper
    &.mod-cursor-default
        .ks-event-image__annotation, .ks-event-image__image, .ks-event-image__spin
            cursor: default
    .ks-event-image__annotation, .ks-event-image__image
        user-select: none

.ks-event-image__border-label
    display: flex
    flex-wrap: nowrap

.ks-event-image__border-name
    margin-left: 5px