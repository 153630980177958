/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * object_marker style
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'colors'

.va-obj-marker ellipse
    fill-opacity 0
    stroke $clMarker
    stroke-opacity 1
    stroke-width 3px

.va-obj-marker circle
    fill $clMarker

.va-obj-marker.va-moving ellipse
    fill-opacity 0.2

.va-obj-marker ellipse:hover
    cursor move

.va-obj-marker .va-xmarker:hover
    cursor ew-resize

.va-obj-marker .va-ymarker:hover
    cursor ns-resize
