/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.seclog-view

  position fixed
  width 100%

  .filter-element
    margin-left 4px

.seclog-table-container

  padding-top 60px

  .seclog-table
    &__time-th
      width 250px
    &__user-th
      width 200px
    &__email-th
      width 200px
    &__role-th
      width 200px
    &__ip-th
      width 150px
