/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.value-selector
    .value-selector-all-title
        display inline-block
        overflow hidden
        max-width 300px
        text-overflow ellipsis
        vertical-align middle
    .caret
        display inline-block
        margin-right 10px
        margin-left 10px
    .mCSB_inside
    .mCSB_container
        margin-right 12px
        &.mCS_no_scrollbar_y.mCS_y_hidden
            margin-right 0
    .dropdown-menu
        width: 340px
        max-height: 400px
        &.mod-max-width
            width: auto
            max-width: 340px
    .value-group
        border-top 1px solid #ccc
        font-weight bold
    .value-element
        font-weight normal
    .value-selector-checkbox
        margin 0
        margin-bottom 2px
        vertical-align middle
    .value-selector-section
        padding 5px 10px
    .link-button, .link-button-right
        display inline-block
        font-size 12px
    .link-button-right
        padding 5px 10px 5px 0
        cursor pointer
        float right
    .value-group, .value-element, .link-button
        padding 5px 5px 5px 10px
        cursor pointer
    .value-group, .value-element
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
        &:hover
            background-color #428bca
            color white
        &.disabled
            opacity 0.7
    &.mod-none-max-height
        .dropdown-menu
            max-height: inherit

.middle-width-selector
  .value-selector-all-title
    max-width 190px
  .dropdown-menu
    width 260px

.min-width-input-date
    min-width: 200px

.cameras-selector
  .value-selector-all-title
    max-width 230px
  .dropdown-menu
    width 300px

.value-select-field
    cursor: pointer