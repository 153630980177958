/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
 @require 'style/settings/colors'

.is-drop-hover
  opacity 0.7

.is-drop-active
  background-color shade($baseColor--light, 10%)
  box-shadow inset 0 -1px 31px 0 tint($baseColor--dark, 25%)

.is-dragging
  opacity 0.6

.ui-draggable-dragging
  outline tint($baseColor--dark, 25%) dotted 2px
