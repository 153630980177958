/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * settings.styl
 *
 * Description : style for settings dropdown form
 *
 * Created by Sergey Gaychuk on 18/05/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

.kp-settings-form
    flex-direction: column
    z-index 11

.kp-settings-form-line
    margin-bottom 10px

.kp-settings-form-label
    text-align left
    min-width 100px
    display inline-block

.kp-settings-form-value
    text-align right
    display inline-block
    width 114px
    select
        width 100%
        color $clText
        background $clBtnBgd
    select[disabled]
        cursor: not-allowed

.kp-settings-form-btn-panel
    text-align right
