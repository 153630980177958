/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.datetimeModal
    min-width: 700px

.datetimeModal-body
    display: flex
    justify-content: space-between
    flex-wrap: nowrap
    align-items: center

.datetimeModal-period-btn
    display: flex
    label
        flex: 1

.datetimeModal-btn-now
    width: 100%