/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
//@require 'modal'
//@require 'thumbnails'
//@require 'horizontal_tabs'
@require 'style/settings/z_index'
@require 'style/settings/sizes'

body
  padding-top $navHeight

.nav > li
  .toolbar-icon
    cursor pointer
    padding 12px 5px
    width 50px
    height $navHeight
    text-align center
    &.disabled
      cursor default

.toolbar-icon-container
    width 60px !important

.toolbar-icon-container-item
    vertical-align middle
    display inline-block
    width 26px
    height 26px

.navbar.navbar-default
  margin-bottom 0
  border-right: 0 !important

.navbar .nav li .navbar-profile-img-container
  height: 50px
  padding-top: 5px
  padding-bottom: 5px

input.ng-invalid:not(.ng-pristine), textarea.ng-invalid:not(.ng-pristine), select.ng-invalid:not(.ng-pristine)
  color #b94a48
  border-color #ee5f5b
  -webkit-box-shadow 0 0 6px #f8b9b7 !important
  -moz-box-shadow 0 0 6px #f8b9b7 !important
  box-shadow 0 0 6px #f8b9b7 !important

input.ng-invalid:focus:not(.ng-pristine), textarea.ng-invalid:focus:not(.ng-pristine),
select.ng-invalid:focus:not(.ng-pristine)
  border-color #e9322d

.app-position-relative
  position: relative

.app-clickable
  cursor pointer

.disabled-as-normal
  &.btn.disabled,
  &.btn[disabled],
  fieldset[disabled]& .btn
    opacity 1.0

  &.form-control[disabled],
  &.form-control[readonly],
  fieldset[disabled]& .form-control
    background-color #fff
    cursor default

.st-scs
  color #34c234 !important
.st-wrn
  color #d9534f !important
.st-offline
  color #777 !important
.st-off span
  color #777 !important

.scs
  background-color #5cb85c !important
.wrn
  background-color #d9534f !important
.none
  background-color #fff !important

@keyframes blink
  0%
    opacity 1.0
  50%
    opacity 0.0
  100%
    opacity 1.0
.blink
  animation blink 1s step-start 0s infinite

.ng-hide.display-block
  display block !important

.badge
  border-radius 4px
  padding 3px

.danger-color
  color #d9534f !important
.success-color
  color #57b85a !important
.warning-color
  color #d58512 !important
.info-color
  color #166688 !important

.bolder
  font-weight bold

.datetimepicker
  .switch
    white-space nowrap

.text-ellipsis
  white-space nowrap
  overflow hidden
  text-overflow ellipsis

.dropdown-menu
  z-index: $zIndex.dropdownMenu

.table-btn-actions
  padding 0 2px 0 2px
  margin-right 5px
  float left

.popover
  max-width 500px !important
  word-wrap break-word
  word-break break-all

// Its for hide input field. https://github.com/angular-ui/ui-select/issues/1797
// Don`t should use search-enable=false becouse its occurs error on click arrow in ui-select
.disable-search .ui-select-search
    display: none

@require 'dragdrop'
@require 'list'
@require 'ie'
@require 'checkbox'
