/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.admin-licenses-view
  $padding = 15px
  padding-left $padding

  .toolbar-wrapper
    margin-top 15px
  .row
    padding-left $padding
