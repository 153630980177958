/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * go_live_btn.styl
 *
 * Description : style for "go live" button
 *
 * Created by Maxim Rodionov on 10/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */

@require 'kp/player/common/conf'

.kp-go-live-btn
    width 62px // hardocded to max button length

.kp-go-live-btn.is-active
    background-color: $clLive
