/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * download.styl
 *
 * Description : style for download dropdown form
 *
 * Created by Maxim Rodionov on 09/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

.kp-dl-text-span
    margin-right: 8px
    margin-left: 8px

.kp-dl-duration-label
    display: inline-block
    text-align: center
    min-width: 80px

.kp-dl-total-ranges
    display: inline-block
    min-width: 40px
    color: $clMutedText
    opacity: 0
    &.is-multiple-ranges
        opacity: 1