/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * playpause.styl
 *
 * Description : style for play/pause controls
 *
 * Created by Maxim Rodionov on 05/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

.kp-play
    position absolute
    top $toolBoxTop
    left $playpauseLeft
