/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require './value_selector.styl'
@require '../event_image/event_image.styl'
@require '../count_faces_modal/count_faces_modal.styl'
@require '../berlio_check_modal/berlio_check_modal.styl'
@require 'style/settings/sizes'
@require 'style/settings/colors'
@require 'style/settings/z_index'

.no-events
    height: s('calc(100vh - %s - %s)',$navHeight, $eventsToolbarHeight)

.events-view
    background: $mainBgColor
    display: flex
    flex-direction: column
    height: calc(100vh - 55px)
    overflow-y: auto
    overflow-x: hidden
  .view-toolbar
      width 100%
      flex-grow: 1
  .events-view-inner
      flex-grow: 2
      overflow: auto
      height: 100%
      padding 10px

  .content-with-sidebar
    top $eventsToolbarHeight
    right 0

  .ks-progress
    margin-bottom 0
    width 100%

  .text-filter-size
    input
      width 200px !important

  .cameras-selector
    .title
      font-weight bold
      margin-top 5px

  .camera-snapshot
    margin-top 5px
    .thumbnail
      padding 0 !important
      width 100%
      height 120px
      vertical-align middle
      margin-bottom 0
      &:hover
        cursor pointer
        box-shadow 1px 1px 2px 1px $borderColor--base
    .selected
      position: relative
      border-width: 5px
      border-color: $selectionColor !important

  .sidebar-group
    margin-bottom 30px
    .sidebar-group-title
      font-weight bold
      text-decoration underline
      margin-bottom 8px

.form-group .form-control
  padding 6px 3px

.form-group.filter-static .form-control.filter-date
  background-color #fff
  width 62px
  text-align center

.form-control.filter-time
  background-color #fff
  width 70px
  text-align center

.filter-time-dropdown
  min-width 200px

.form-control.filter-datetime
  cursor pointer

.filter-collapsed-container
  margin-top 35px
  margin-left 45px

.filter-collapsed-container.dropdown-menu
  left auto
  right 0

.filter-collapsed-btn
  display none
  position relative
  float right
  margin 0
  padding 9px 10px
  background-color transparent
  border 1px solid  $borderColor--base
  border-radius 4px

.filter-collapsed-btn .icon-bar
  display block
  width 22px
  height 2px
  border-radius 1px
  background-color #cccccc

.filter-collapsed-btn .icon-bar + .icon-bar
  margin-top 4px

.filter-collapsed-btn:hover, .filter-collapsed-btn:focus
  background-color #dddddd

.filter-collapsed-list
  padding 0 5px
  padding-bottom 5px

.filter-collapsed-list div.form-group
  margin-top 5px

.filter-collapsed select.form-control
  width 200px

.filter-view-selector
  background-color #fff
  border 1px solid $borderColor--base

.filter-view-list
  min-width 0

.filter-view-list li:hover
  background-color #428bca

.filter-view-list li
  width 56px
  padding 5px
  display inline-block
  cursor pointer

.filter-view-list li img
  display block
  margin auto

.filter-cameras-list
  padding-left 0
  margin-bottom 0
  max-height 250px

.filter-cameras-snapshot-thumbnail
    vertical-align middle
    height 200px
    width 300px
    padding 3px


.filter-cameras-snapshot
    width: 80px
    height: 55px

.filter-cameras-search
  width 100%
  padding 5px
  .input-group-btn
    width 1% !important

.filter-priority-level-label
  margin-top 5px
  margin-right 2px
  width 10px
  border-radius 5px
  height 10px
  float right

.filter-total-count
    display: inline-block
    vertical-align: middle
    margin-top: 5px
    margin-left: 15px
    font-weight: bold

.low
  background-color $event_low_level_color !important
.middle
  background-color $event_middle_level_color !important
.high
  background-color $event_high_level_color !important
.critical
  background-color $event_critical_level_color !important

.event-level-border-color-low
  border-color $event_low_level_color !important
.event-level-border-color-middle
  border-color $event_middle_level_color !important
.event-level-border-color-high
  border-color $event_high_level_color !important
.event-level-border-color-critical
  border-color $event_critical_level_color !important

.state-ok
  color $stateOkColor

.state-warning
  color $stateWarningColor

.state-error
  color $stateErrorColor

.text-column-filter-container
  margin-top 7px

.text-column-filter
  text-decoration none !important

.filter-column-list li
  padding-left 10px

.photo-img-header
  background-color rgba(180,180,180,0.8)
  color #fff
  position absolute
  padding-left 5px
  padding-right 5px
  right 0
  top 0
  cursor pointer
  z-index 10

  p
    margin 0

.photo-filter-link
  cursor pointer
  p
    margin 4px 0
    padding 0 3px

.photo-annotation
  display inline-block
  position absolute
  top 0
  left 0
  z-index 2
  width 100%
  height 100%

.f14
  margin 4px 0
  font-size 14px

.f13
  font-size 13px
  color #0074c7

.f12
  font-size 12px
  color #5c5c5c

.events .thumbnail
  display inline-block
  width 100%
  margin 3px 0
  -webkit-box-shadow: rgba(0,0,0,0.3) 0 1px 3px
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px
  box-shadow: rgba(0,0,0,0.3) 0 1px 3px
  border: none !important

.events .event
  &.processed-alarm
    background-color $processedAlarmColor

  &.processed-dismiss
    background-color $processedDismissColor

.narrow
  padding 0 3px


.events > .event-card, .event-card > .event
  padding: 8px 8px 5px 8px !important

.event-card__title
    display: flex
    padding: 5px 3px 0 3px
    height: 25px

.event-card__title-icon
    min-height: 20px
    height: 20px
    min-width: 20px
    width: 20px

.event-card__title-text
    margin: 0 5px
    font-size: 13px
    color: black
    span
        vertical-align: middle

.event-card__title-ctrls
    margin-left: auto
    display: none
    .event-card:hover &, .event-card.is-active &
        display: block
    .dots-menu__btn
        padding: 0 12px
        background-color: inherit

.event-card__title-badge
    cursor: pointer
    min-width: 20px
    border-radius: 10px
    span
        vertical-align: middle

.event-card__title-search-similarity
    margin-right: 3px
    flex-shrink: 0

.text-filter-size
  .input-group-btn
    width 1px !important

.search-navbar
  padding-left 0
  padding-right 0

  .search-form-filter
    .form-group
      float left
      display block
      margin-right 4px
      margin-bottom: 7px
  .select-priority-control
    .ui-select-match-text
      margin-right 20px
    .ui-select-match.ng-hide
      display block !important
  .select-priority-control
    .dropdown-menu
      min-width 250px
      width 100%

.priority-level-label
  margin-top 5px
  margin-right 2px
  width 10px
  border-radius 5px
  height 10px
  float left

.priority-level-label.lower
  margin-top 2px


.events-view__info-messages
    margin-bottom: 10px


.events-view__info-message
    margin-bottom: 5px

.event-not-clickable
    .ks-event-image__image, .ks-event-image__annotation
        cursor: default

@require 'events/filter/**/*.styl'
