/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'kp/plan/common/conf'

.kp-u-icon // use parent-container to set a fixed height or it get default 'replacement element' height (150px)
    width 100%
    max-height 100%
    fill currentColor

.is-draggable
    fill: #fff
.is-connected
    fill: $clSuccess
.is-error
    fill $clError
.is-no-signal
    fill $clMuted

.kp-u-responsive-centered-img
    display block
    height auto
    max-height 100%
    max-width 100%
    position relative
    top 50%
    left 50%
    transform translate(-50%, -50%)

.kp-u-flex-center-cont
    display: flex
    align-items: center
    justify-content: center

.kp-u-text-truncate
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
