/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-location-status-icon.mod-small
    height: 19px
    width: 19px

.ks-location-status-icon.mod-inline
    display: inline-block
    vertical-align: top

.ks-location-status-icon__icon
    height: 100%
    width: 100%

.ks-location-status-icon.mod-online
    .ks-location-status-icon__icon
        color: #34c234

.ks-location-status-icon.mod-offline
    .ks-location-status-icon__icon
        color: #777

.ks-location-status-icon.mod-armed
    .ks-location-status-icon__icon
        color: #d9534f

.ks-location-status-icon.mod-disarmed
    .ks-location-status-icon__icon
        color: #34c234

.ks-location-status-icon.mod-has-bypassed
    .ks-location-status-icon__icon
        color: #d58512
