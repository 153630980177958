/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Container used to hold row of buttons
**/
$rowPadding = 8px

.kp-row
    display: flex
    justify-content: flex-start
    align-items: center
    &__el
        margin: 0 ($rowPadding/2)
