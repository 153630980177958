/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-incident-filter
    flex: 1

.ks-incident-filter__field
    display: inline-block
    vertical-align: middle
    margin-top: 5px

.ks-incident-filter__field-id
    input
        width 200px !important
    .input-group-btn
        width 1px !important

.ks-incident-filter__text-search-popover
  font-size: 11px
  width: 200px

  ul
    margin-bottom: 0
