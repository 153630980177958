/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.text-search-popover
  font-size 11px
  width 250px

  ul
    margin-bottom 0
