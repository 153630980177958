/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Page header component style
 * now used on platelist,facelist page


 *
 * Maxim Rodionov <max.rodionov@synesis.ru>
**/
.page-header
    display: flex
    align-items: center
    justify-content: flex-start
    margin-top: 20px
    margin-bottom: 10px

.page-header__item.mod-preserve-width
    flex-shrink: 0

.page-header__item.mod-right
    padding-left: 15px
    // margin-left: auto

.page-header__item.mod-h3-text
    font-size: 24px
    font-weight: 500
    margin-right: 20px