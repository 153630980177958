/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.mobile-table-container
    padding 10px
    font-size 16px

.table-row .mobile-add-btn
    margin 3px
    cursor: pointer
