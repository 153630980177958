/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * rect_marker.styl
 *
 * Description : style for rect_marker component
 *
 * Created by Maxim Rodionov on 26/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'colors'

.va-rect-marker
    &-body
        fill-opacity 0
        cursor move
    &-side
        stroke $clMarker
        stroke-opacity 1
        stroke-width 3px

.va-rect-marker-side.mod-x-resizable
    cursor ew-resize

.va-rect-marker-side.mod-y-resizable
    cursor ns-resize

.va-rect-marker-body:hover
    fill-opacity 0.2

