/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
 @require 'style/settings/colors'

.horizontal-tabs
    display flex
    margin 0
    list-style none
    align-items flex-end
    padding-left 0
    overflow visible

.horizontal-tabs-li
    position relative
    margin 10px -8px 0 0
    flex-basis 160px
    height 30px
    color $fontColor--light
    overflow hidden
    float left
    padding-right: 25px;
    &:hover
        .horizontal-tabs-li-box
            background-color $tabColor--onhover
            box-shadow inset 0 0 1px 1px $baseColor--ultraLight

.horizontal-tabs-li-box
    position: absolute;
    top: 0;
    width: 96%;
    height 40px
    border 1px solid $borderColor--base
    border-bottom 0
    border-top-left-radius 8px
    border-top-right-radius 8px
    background $tabBgColor
    margin 0 3px 0
    box-shadow inset 0 0 1px 1px #fff
    transform perspective(170px) rotateX(40deg)
    transition background 0.3s ease


.horizontal-tabs-li.is-active
    z-index 40
    padding-bottom 1px
    box-shadow 0 1px 0 0 white
    .horizontal-tabs-li-box
        z-index 50
        background white
    .horizontal-tabs-li-a
        color $fontColor--base
        z-index 70


.horizontal-tabs-li-a
    position relative
    top 5px
    left 7px
    width 120px
    padding 6px 5px 3px 8px
    line-height 100%
    height 100%
    display inline-block
    white-space nowrap
    text-overflow clip
    overflow hidden
    max-width 100%
    color $fontColor--light !important
    text-decoration none  !important
    &:hover
        color $fontColor--base
        text-decoration none

.horizontal-tabs-close
    position absolute
    bottom 3px
    right 12px
    font-size 1.2em
    z-index 70

.horizontal-tabs-add
    height 40px
    cursor pointer

.horizontal-tabs-add-a
    line-height 60px
    padding-left 15px
    color $fontColor--light
    &:hover
        color $fontColor--light

.horizontal-tabs-add-a.is-disabled
    color $fontColor--muted

.horizontal-tabs-add-icon.fa-plus
    font-size 14px !important
