/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for PTZ plugin
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'

.ptz-dialog
    position absolute
    width: 100%
    left: 0
    bottom: 5px
    display: flex
    justify-content: center
    &__cont
        background $clDialogBgd
        padding: 8px

.ptz-zoom-rect
    fill: alpha(white, 0.5)

.ptz-mouse-tracker-zone
    background: alpha(red, 0.0)

.ptz-mouse-area
    cursor: crosshair

.kp-player-dialog
    &__ctrl
        flex-wrap: wrap
        max-width: 100px
    &__ctrl-row
        display: flex
        flex-wrap: nowrap
        width: 100%
        justify-content: space-between
    .mod-top-left svg
        transform: rotate(45deg);
    .mod-top svg
        transform: rotate(90deg);
    .mod-top-right svg
        transform: rotate(135deg);
    .mod-right svg
        transform: rotate(180deg);
    .mod-bottom-right svg
        transform: rotate(-135deg);
    .mod-bottom svg
        transform: rotate(-90deg);
    .mod-bottom-left svg
        transform: rotate(-45deg);