/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for faceimage icon
 *


 *
 * Maxim Rodionov <max.rodionov@synesis.ru>
**/

.fimage-status-icon
    height: 30px
    width: 33px
    color: #f9ec86
    opacity: 0.8
    font-size: 17px
    display: inline-block
    line-height: @height
    vertical-align: middle
    text-align: center
    background-color: rgba(0, 0, 0, 0.4)

.fimage-status-icon.mod-no-bgd
    background-color: transparent