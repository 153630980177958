/*
 * Copyright (C) 2018 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.location-selector-dropdown
    $locationDropdownWidth = 200px

    &__title
        display block
        overflow hidden
        width $locationDropdownWidth - 40
        text-overflow ellipsis

    .dropdown-toggle
        width $locationDropdownWidth
        text-align left

    &__menu-row
        display block
        overflow hidden
        text-overflow ellipsis

    .dropdown-menu
        min-width $locationDropdownWidth
        max-height 400px
        width $locationDropdownWidth + 200
        overflow-y auto
        .pager-ctrls
            padding 5px 0 10px

    .ks-location-status-icon
        font-size 9px

