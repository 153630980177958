/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
// (c) 2015 Synesis LLC.
.cam-list
    border-left: 1px #333 solid
    height: 100%
    margin: 5px
    position: absolute
    overflow: hidden
    bottom: 0
    top: 0
    right: 0
    left: auto
    width: 301px
    background: #1B1B1B

.timeline-groups .control
    height: 123px

.tool-box .timeline-groups
    top: -95px

.centered-snapshot
    display: inline-block
    position: relative
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.cam-list__element
    padding-left: 5px
    margin-top: 5px

.cam-list__slider
    list-style: none
    padding: 3px
    height: 100%
    img
        max-width: 100%
        max-height: 100%
        margin: 0 auto

.cam-list .mCSB_inside .mCSB_container
    margin-right: 0

.event-header
    display: flex
    justify-content: space-between
    position: absolute
    top: 0
    width: 100%

.event-header__name
    background-color: rgba(100,100,100, 0.8)
    z-index: 5
    color: #eee
    padding: 0 3px
    font-size: 12px
    font-weight: bold
    width: auto
    min-width: 1px
    flex-shrink: 1

.event-header__time
    flex-shrink: 0
    background-color: rgba(100,100,100, 0.8)
    z-index:5
    color: #eee
    padding: 0 3px
    font-size: 12px
    font-weight: bold

.cam-list a
    text-decoration: none

.cam-list__lazy-img
  width: 20px !important
  height: 20px !important
  top: 45%
  position: relative

.cam-list__lazy-img.on-image
  position: absolute
  left: 45%
