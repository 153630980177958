/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.loader-img
  max-height 50px !important
  max-width 50px !important
  margin 0 !important

.with-onimage-labels
  position relative

  .onimage-label
    padding 2px 6px 2px 6px
    text-align right
    background-color rgba(82, 82, 82, 0.8)
    color rgba(255, 255, 255, 0.8)
    font 11px/15px Arial, Helvetica, sans-serif
    border-radius 2px
    letter-spacing: 0.1px

  & > .onimage-label, .onimage-container
    position absolute
    z-index 3

  .onimage-container > div
    float left
    margin-right 5px

  .onimage-label.left-top, .onimage-container.left-top
    left 10px
    top 10px

  .onimage-label.right-top, .onimage-container.right-top
    right 10px
    top 10px

  .onimage-label.left-bottom, .onimage-container.left-bottom
    left 10px
    bottom 10px

  .onimage-label.right-bottom, .onimage-container.right-bottom
    right 10px
    bottom 10px


@require './snapshot_img.styl'
