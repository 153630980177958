.mode
    background #618c61
    right 5px
    bottom 5px
    position absolute
    border 0 solid transparent
    border-radius 2px
    margin-bottom 0
    font-size 12px
    line-height 14px
    text-align center
    white-space nowrap
    display inline-block
    color #fff
    padding 5px
    z-index 1
    opacity 0.4
    &:hover
        opacity 1
