/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-incident-event
    position: relative
    border-radius: 4px
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15)
    background-color: white
    border: 2px solid white
    padding: 4px
    .mod-not-clickable
        .ks-event-image__image, .ks-event-image__annotation
            cursor: default

.ks-incident-event__image
    width: 100%
    height: s('calc(100% - %s)', 15px)

.ks-incident-event__title
    padding-top: 5px

.ks-incident-event__delete
    width: 18px
    height: 18px
    position: absolute
    top: -5px
    right: -5px
    cursor: pointer
    z-index: 4
    background-color: white
    border-radius: 50%
    text-align: center
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3)

    display: none
    .ks-incident-event:hover &
        display: block

.ks-incident-event__delete-icon
    vertical-align: text-top
    &:hover
        color: #000
