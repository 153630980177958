/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.lt-rect
  height 110px

.layouts-container
  background $mainBgColor
  padding 10px 6px !important

@require './layout_icon.styl'
@require './layout_cell.styl'
