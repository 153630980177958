/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.locations-row
  margin 0 0 0 0

.locations-element
  padding-right 3px
  padding-left 3px

.locations-element .thumbnail
  display inline-block
  width 100%
  margin 3px 0

.location-name
  max-width 300px
  font-size 16px
  overflow hidden
  text-overflow ellipsis

.location-status-wrapper
    margin-right: 5px
    .ks-location-status-icon
        width: 28px
        height: 28px

.location-add-btn-text
  padding-top 10px

.location-modal-tab-content
  height 490px
  padding-top: 15px

.location-modal
  margin-bottom 0
  .usergroups-add-btn
    cursor pointer
  .table-row
    &:hover
      cursor pointer
    &.is-selected
      background-color #f5f5f5

.location-modal-map
  width: 100%
  height 400px
  margin-bottom 0
  margin-top 10px

.location-modal-regulation
  width 100%
  height 90%
  margin-bottom 0
  margin-top 10px
  resize none

.select-area
  background-color #f5f5f5
  border 1px solid #ccc
  border-radius 4px
  padding 10px 0

  .progress
    margin-left 15px
    .progress-bar
      width 100%

  .mCSB_inside > .mCSB_container
    margin-right 15px

  .camera-snapshot-wrapper
    margin-top 5px
    .thumbnail
      width 100%
      height 120px
      &.is-selectable:hover
        cursor pointer
        border-color rgba(66, 139, 202, 0.72)

.select-area-camera-snapshot
  vertical-align middle
  margin-bottom 0
  transition none

.column-header
  margin-bottom 10px

.column-camera-select > .column-header > .column-title
  font-weight bold
  max-width 260px

  &.column-title-text
    margin-top 7px

.map-row
  padding: 3px 5px

.map-size
  width 1200px
  height 410px

.marker-label
  top 5px !important
  border-color #000 !important
  background-color #000 !important
  color #eee !important
  border-width 1px !important
  line-height 1em !important
  word-wrap break-word
  white-space normal
  max-width 200px

.marker-label:before
  top 1.45px !important
  left -5px !important

.leaflet-control-layers.leaflet-control
  display none

.leaflet-popup
    margin-bottom: 27px

.leaflet-popup-content-wrapper
    background: transparent
    box-shadow: none
    padding: 0

.leaflet-popup-tip-container
    display: none

.location-cluster
  background-color rgba(102, 102, 102, 0.60) !important
  color #fff !important

.location-cluster div
  background-color rgba(35, 102, 226, 0.90) !important

.cluster-arm div
  background-color #a33 !important

.cluster-disarm div
  background-color #3a3 !important

.cluster-offline div
  background-color #333 !important

.hide-map-filter
  display none


.input-filter-size
  margin-left 14px

.icon-size
  padding 5px
  padding-right 2px
  border-radius 15px

.regulations-view
    border: none

.usergroup-form-group
    margin-bottom: 15px

.sidebar-list__control-group .pager-ctrls
    flex-wrap: wrap
    justify-content: center
    margin-bottom: 15px

.sidebar-list__control-group .pager-ctrls__range-info
    margin-bottom: 10px
    text-align: center

@require '../regulation_modal/regulation_modal.styl'
@require '../leaflet_map/leaflet_map.styl'