/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Anton Kulikovskiy <anton.kulikovskiy@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-ticket-filter
    flex: 1
    &__field
        display: inline-block
        vertical-align: middle
        margin-top: 5px
        .value-selector .value-selector-all-title
            max-width: 140px
        .input-group
            max-width: 220px
    &__hidden-btn
        display: none