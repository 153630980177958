/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * File name   : layout_icon.css
 *
 * Description : style for layout_icon svg widget
 *

 *
 * Modification History:
 * Date        Name                Description
 * 28/01/2015  Maxim Rodionov      Initial version
 */
.kx-svg-layout rect
   fill #dcdcdc
   stroke white
   stroke-width 4px

.kx-svg-layout .kx-svg-layout-filled-cell
  fill rgba(66, 139, 202, 0.89)

.kx-svg-layout .kx-svg-layout-bgd
  fill #dcdcdc
  stroke #555
