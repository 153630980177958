/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * icon_btn.styl
 *
 * Description : style for icon buttons
 *
 * Created by Maxim Rodionov on 10/03/2015
 *
 * (c) 2015 Synesis LLC.
 * All Rights Reserved.
 */
@require 'kp/player/common/conf'

.kp-icon-btn
    color $clText
    cursor pointer
    width $iconSize
    height $iconSize
    line-height: $iconSize
    m-user-select(none)

.kp-icon-btn:hover
    color $clMutedText

.kp-icon-btn.is-disabled
.kp-icon-btn.is-disabled:hover
    opacity .65
    cursor not-allowed
    color $clText // to override .kp-icon-btn:hover
