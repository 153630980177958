/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for snapshot labels, used in event card, layout cell
**/
.snapshot-label
    padding: 2px 6px 2px 6px;
    text-align: right;
    background-color: rgba(82,82,82,0.8);
    color: rgba(255,255,255,0.8);
    font: 12px/15px Arial, Helvetica, sans-serif;
    border-radius: 2px;
    letter-spacing: 0.1px;

.snapshot-label.mod-right
    position: absolute
    top: 7px
    right: 7px

.snapshot-label.mod-left
    position: absolute
    top: 7px
    left: 7px