/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.ks-snapshot-img
    width 100%
    height 100%
    background-color black
    overflow hidden

    .img
        position absolute

    .empty-img
        width 100%
        height 90%
        color rgba(149,149,149,0.8)

    .camera-label
        right 10px
        text-align left
        font-weight: bold

    .camera-global-icon
        width: 20px
        height: 20px
        position: absolute
        right: 10px
        bottom: 10px
        z-index: 1
        svg
            fill: rgba(255,255,255,0.75)
