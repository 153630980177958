/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Yura Yakusevich <yura.yakusevich@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.player-dt
    position: relative

.player-dt__input
    display none

.player-dt__popup
    position: absolute
    cursor: pointer
    top: -5px
    left: 50%

.player-dt__popup-body
    visibility: hidden
    width: 260px
    background-color: #333
    color: #fff
    text-align: center
    border-radius: 6px
    padding: 8px 0
    position: absolute
    z-index: 1
    bottom: 125%
    left: 50%
    margin-left: -40px
    &.show
        visibility: visible
    &::after
        content: ""
        position: absolute
        top: 100%
        left: 50%
        margin-left: -5px
        border-width: 5px
        border-style: solid
        border-color: #333 transparent transparent transparent

.player-dt__timepicker
    margin-bottom: 10px
    margin-top: 5px

.player-dt__timepicker-select
    color: white
    background: #222
    border: 1px solid #484747
    padding: 2px 4px
    margin-right: 5px
    min-width: 42px
    border-radius: 4px

.player-dt__error
    color: #cc3a3a
    padding: 10px
    font-size: 14px
    display: none

.player-dt__c-btn
    background: #4e653d
    min-width: 128px
    min-height: 26px
    outline: none
    border: 2px solid transparent
    margin-bottom: 10px
    margin-top: 10px
    border-radius: 4px
    &:hover
        background: #4b6935
