/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

.ks-alarms-queue-filter__header
    display: flex

.ks-alarms-queue-filter__header-title
    font-weight: bold
    cursor: pointer

.ks-alarms-queue-filter__header-clear
    margin-left: auto

.ks-alarms-queue-filter__locations-title
    font-weight: bold
    margin-top: 25px
    margin-bottom: 10px

.ks-alarms-queue-filter__status
    .ui-select-match-text
        overflow: hidden

.ks-alarms-queue-filter__status-select
    vertical-align: bottom
