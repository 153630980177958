/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
.location-regulation-modal
  textarea
    resize none
    &[disabled]
      background-color #fff !important
      cursor default
      