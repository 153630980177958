/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Style for resource not found page
 *


 *
 * Maxim Rodionov <max.rodionov@synesis.ru>
**/
.not-found
    padding: 15px
    width: 400px
    margin: 40px auto
    &__url
        margin-bottom: 20px

