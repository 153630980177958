/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Adara Belaya <adara.belaya@synesis.ru>, Synesis LLC www.synesis.ru.
 */
@require 'style/settings/colors'

.dots-menu__btn
    padding: 0 6px
    &:hover
        background-color: $btnBgColorHover

.dots-menu__btn.is-active
    outline: 5px auto -webkit-focus-ring-color
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125)

.dots-menu__option
    cursor: pointer

.dots-menu.mod-no-background
    .dots-menu__btn
        background-color: inherit
        &:hover
            background-color: inherit

.dots-menu.mod-condensed
    .dots-menu__btn
        padding: 0

.dots-menu.mod-hover-background
    .dots-menu__btn
        background-color: inherit
        &:hover
            background-color: $btnBgColorHover
