/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Maxim Rodionov <max.rodionov@synesis.ru>, Synesis LLC www.synesis.ru.
 */
/*
 * Cutsom style for pikaday
 *
 * (c) 2016 Synesis LLC.
 * All Rights Reserved.
**/
@require 'kp/player/common/conf'

.kp-pika
    // datepicker lives out of player div so we need
    // override bootstrap modal z-index in modal mode
    // override fullscreen z-index in fullscreen mode
    z-index: 2147483648 !important
    .is-today .pika-button // revert pika default is-today style due to incorret today determining
        color: #666
        font-weight: normal
    &.mod-in-player
        margin-top: -5px
    &.mod-datetimepicker
        border: 1px solid transparent !important
    &.mod-dark-theme
        color: #fff
        background: #333
        border: 1px solid #666
        border-bottom-color: #999
        .pika-label
            background-color: #333
        .pika-select
            background-color: #333
        .pika-prev, .is-rtl .pika-next
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAQAAACGG/bgAAAAQ0lEQVR4Ae3KIQ4AIBTD0N0/IeHGI3UIRA3ut/Zl+ltXc5++htVAmIAwAWECwgSEKbgthEoIlRAqIVRCqINQB9nDgQd7ktwFo6UpWQAAAABJRU5ErkJggg==');
        .pika-next, .is-rtl .pika-prev
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAQAAACGG/bgAAAAP0lEQVQ4y+3TMQoAMAgEwfwfAvvjTZ1uGzuvHhBPPGczEG+FRqqRaqQaqUaqkX6QBmmjacvQ6qEVTjsh+xizebvlaWptGXZAAAAAAElFTkSuQmCC');
        .pika-table th
            color: #999
        .pika-button
            color: #fff
            background: #222
        .pika-week
            color: #999
        .is-selected .pika-button
            color: #fff !important
            background: $clBtnActiveBgd
        .is-disabled .pika-button
            color: #999
            opacity: .3
        .pika-button:hover
            background: $clBtnHoverBgd
        .pika-button:active
            background: $clBtnActiveBgd
