/*
 * Copyright (C) 2017 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'style/settings/sizes'

.bills-view
    height: s('calc(100vh - %s)', $navHeight)
    display: flex
    flex-direction: column

.bills-view__table-header
    margin-bottom: 0

.bills-view__bills-wrapper
    flex-grow: 2
    overflow: auto
