/*
 * Copyright (C) 2016 Synesis LLC. All rights reserved.
 * Author Dmitry Lazurkin <dmitry.lazurkin@synesis.ru>, Synesis LLC www.synesis.ru.
 */

@require 'style/settings/sizes'

.incidents-view
    height: s('calc(100vh - %s)', $navHeight)
    display: flex
    flex-direction: column

.incidents-view__incidents-wrapper
    flex-grow: 2
    overflow: auto
    background: #eee

.incidents-view__incidents
    height: 100%
    padding: 20px 40px

.incidents-view__incident
    box-shadow: rgba(0,0,0,.3) 0 1px 3px
    background: white
    margin-bottom: 20px
